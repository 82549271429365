import store from 'core-web/state/store';
import { getProductByPartNo, resolveSearch, resolveSlug } from './actions';

export { default as PageReducers } from './reducers';

export default {
    resolveSlug: (slug, search, parameters) => {
        store.dispatch(resolveSlug(slug, search, parameters));
    },

    resolveSearch: (search) => {
        store.dispatch(resolveSearch(search));
    },

    getCurrentSearch: () => {
        let returnValue = '';
        if (typeof window !== 'undefined') {
            returnValue = window.location.search;
        } else {
            returnValue = store.getState().page.search;
        }
        return returnValue;
    },
    getProductByPartNo: (partNo, parameters) => {
        store.dispatch(getProductByPartNo(partNo, parameters));
    },
};
