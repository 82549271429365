import Cookies from 'js-cookie';
import { getOneTrustConsent } from 'core-web/components/Tracking/utils/getOneTrustConsent';

export default (sfmcId) => {
    const { targetingConsent } = getOneTrustConsent();

    if (targetingConsent) {
        Cookies.set('sfmc_id', sfmcId, { expires: 365 });
    }
};
