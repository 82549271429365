import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (id, parameters = {}) => {
    if (!Object.prototype.hasOwnProperty.call(parameters, 'pricelist_seed')) {
        const { pricelistSeed } = getModel('customer');

        if (pricelistSeed) {
            parameters.pricelist_seed = pricelistSeed;
        }
    }

    return get(`/frontend/product/${id}/qtybreak`, {
        application_id: getModel('application').applicationId,
        ...parameters,
    });
};
