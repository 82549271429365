import GTM from '@grebban/gtm';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';
import { getImpressions } from './utils/impression';

export default async ({ query, products, list, algoliaIndexType }) => {
    const application = getModel('application');

    const impressions = getImpressions(
        products.slice(0, 12).map((item) => {
            if (list) {
                item.list = list;
            }
            return item;
        }),
    );

    const salesforceId = tracking.getSalesforceTrackingKey();

    const objectIds = products
        .slice(0, 20)
        .map(({ objectID }) => objectID)
        .join(', ');

    GTM.addDataLayerEvent('search', {
        query,
        products: impressions,
        currency: application.currency.code,
        algoliaIndexType,
        algoliaDisplayedObjectIDs: objectIds,
        subscriberID: salesforceId || '',
    });
};
