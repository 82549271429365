import { FILTER_SEARCH, FILTER_SEARCH_ERROR, FILTER_SEARCH_SUCCESS } from './constants';

// The initial state of this store.
const initialState = {
    isFetching: false,
    query: '',
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FILTER_SEARCH:
            return {
                ...state,
                isFetching: true,
            };
        case FILTER_SEARCH_SUCCESS:
            return {
                isFetching: false,
                query: action.query,
            };
        case FILTER_SEARCH_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
