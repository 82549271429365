import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Signup from 'core-web/libs/GrebbCommerceAPI/Newsletter/Signup';
import setSalesforceCookie from 'core-web/util/setSalesforceCookie';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import Checkbox from 'theme/components/inputs/Checkbox';
import ConsentWarning from './ConsentWarning';
import NewsletterForm from './NewsletterForm';

const Div = styled('div')();

const StatusMessage = styled('div')`
    display: inline-block;
    border-radius: 15px;
    padding: 10px 12px;
    margin-bottom: 12px;
    font: 700 12px/1em 'Inter';
    color: ${colors.white};
`;

const initialState = {
    email: '',
    errorMessage: null,
    isSignupComplete: false,
    isPrivacyChecked: false,
};

const StyledLink = styled(Link)`
    text-decoration: underline;
`;

const NewsletterSignup = ({ content, isFooter, signupSource }) => {
    const location = useLocation();
    const [state, setState] = useState(initialState);
    const [hideWarning, setHideWarning] = useState(false);

    useEffect(() => {
        setState(initialState);
    }, [location]);

    const validateForm = () => {
        const regexEmail = /^\w+([-.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

        if (!state.email || !state.email.length || !regexEmail.test(state.email)) {
            setState({ ...state, errorMessage: content.statusmessage_invalid });
            return false;
        }

        if (!state.isPrivacyChecked) {
            setState({ ...state, errorMessage: content.statusmessage_policy_error });

            return false;
        }

        if (state.errorMessage) {
            setState({ ...state, errorMessage: null });
        }

        return true;
    };

    const onCheckboxChange = ({ target }) => {
        setState({ ...state, errorMessage: null, isPrivacyChecked: target.checked });
    };

    const onInputChange = ({ target }) => {
        setState({ ...state, errorMessage: null, email: target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (validateForm() && state.isPrivacyChecked) {
            try {
                const result = await Signup({
                    email: state.email,
                    source: signupSource,
                    marketingConsent: true,
                    emailConsent: true,
                });

                const status = result?.data?.status;
                const emailHash = result?.data?.subscriber_id;
                setSalesforceCookie(emailHash);

                if (status === 'failed') {
                    setHideWarning(true);
                }
                if (status !== 'failed') {
                    setState({ ...state, isSignupComplete: true });
                }
            } catch (e) {
                const error = await e;
                if (error !== '') {
                    setState({ ...state, errorMessage: content.statusmessage_error });
                }
            }
        }
    };

    return (
        <>
            {state.errorMessage && (
                <StatusMessage backgroundColor={colors.green}>
                    <span>{state.errorMessage}</span>
                </StatusMessage>
            )}
            {state.isSignupComplete && (
                <StatusMessage backgroundColor={colors.green}>
                    <span>{content.statusmessage_success}</span>
                </StatusMessage>
            )}

            <NewsletterForm
                isFooter={isFooter}
                value={state.email}
                placeholder={content.placeholder_text}
                buttonText={content.button_text}
                label={content.header}
                onSubmit={onSubmit}
                onChange={onInputChange}
            />
            {hideWarning && (
                <ConsentWarning consent={content?.statusmessage_sign_up_again} setHideWarning={setHideWarning} />
            )}
            <Div marginTop="16px">
                <Checkbox
                    id={`newsletter-privacy${isFooter ? '-footer' : ''}`}
                    value=""
                    alignText="after"
                    size="large"
                    isChecked={state.isPrivacyChecked}
                    onChange={onCheckboxChange}
                >
                    {content.policy_text}
                    {content.policy_link?.url && (
                        <>
                            &nbsp;
                            <StyledLink to={content.policy_link.url}>{content.policy_link.text}</StyledLink>
                        </>
                    )}
                </Checkbox>
            </Div>
        </>
    );
};

NewsletterSignup.propTypes = {
    content: PropTypes.object.isRequired,
    isFooter: PropTypes.bool,
    signupSource: PropTypes.string,
};

NewsletterSignup.defaultProps = {
    isFooter: false,
    signupSource: '',
};

export default NewsletterSignup;
