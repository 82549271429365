import GTM from '@grebban/gtm';
import { getProduct } from './utils/product';

export default async (data) => {
    const product = data?.item;

    if (!product) {
        return;
    }

    GTM.addDataLayerEvent('remove_from_cart', {
        ecommerce: {
            remove: {
                // actionField: { list: data.list },
                products: [getProduct(product)],
            },
        },
    });
};
