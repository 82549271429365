import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (ipAddress) => {
    const { companyId, customerId } = getModel('customer');

    const parameters = {
        application_id: getModel('application').applicationId,
        ip_address: ipAddress,
    };

    let token;
    if (auth.currentUser && customerId) {
        token = await auth.currentUser.getIdToken();

        parameters.customer_id = customerId;

        if (companyId) {
            parameters.company_id = companyId;
        }
    }

    const response = await post('/frontend/basket', parameters, token ? { Authorization: `Bearer ${token}` } : {});
    return response.body();
};
