import React from 'react';
import loadable from '@loadable/component';
import Loading from 'theme/components/Loading';

const options = {
    fallback: <Loading />,
};

export default {
    default: loadable(() => import('./ProductGrid'), options),
    campaign_template: loadable(() => import('./ProductGrid'), options),
    module_template: loadable(() => import('./Module'), options),
};
