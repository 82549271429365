import { inBrowser, inServer } from 'core-web/constants';
import useIntersectionObserver from 'core-web/hooks/useIntersectionObserver';
import { generateSizes, generateSrc, generateSrcSet } from 'core-web/libs/image';
import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import styled from 'theme/libs/styled';

const asArray = (x) => {
    if (x) {
        return Array.isArray(x) ? x : [x];
    }

    return [];
};
const isObject = (x) => x && typeof x === 'object' && !Array.isArray(x);
const isSupportingLazy = inServer || !!window.chrome || typeof InstallTrigger !== 'undefined';

/**
 * Example:
 *
 <Image
    height="100%"
    objectFit="contain"
    format="jpg"
    loading={lazyLoadImage ? 'lazy' : null}
    placeholder={productImagePlaceholder}
    alt={imageTitle}
    src={{ url: imageSrc, width: [280, 370, 400, 450, 500] }}
    sizes="25vw"
    />
 */

const shouldForwardProp = (prop) => ['placeholder', 'quality'].indexOf(prop) === -1;

const Img = styled('img', { shouldForwardProp })`
    &:-moz-loading {
        visibility: hidden;
    }
`;

const srcIsPlaceholder = (src) =>
    typeof src === 'object' ? src?.url?.indexOf('placeholder') !== -1 : src?.indexOf('placeholder') !== -1;

export const LazyImage = ({ alt, placeholder, sizes, src, srcSet, ...rest }) => {
    const [entry, ref] = useIntersectionObserver({ triggerOnce: true });
    const isSafari = inBrowser && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    useLayoutEffect(() => {
        if (entry.target && (entry.isIntersecting || isSupportingLazy || isSafari)) {
            if (entry.target.dataset.src && entry.target.src !== entry.target.dataset.src) {
                entry.target.src = entry.target.dataset.src;
            }

            if (entry.target.dataset.srcSet) {
                entry.target.srcset = entry.target.dataset.srcSet;
            }

            if (entry.target.dataset.sizes) {
                entry.target.sizes = entry.target.dataset.sizes;
            }

            requestAnimationFrame(() => {
                if (entry.target.dataset.src) {
                    delete entry.target.dataset.src;
                }
                if (entry.target.dataset.srcSet) {
                    delete entry.target.dataset.srcSet;
                }
                if (entry.target.dataset.sizes) {
                    delete entry.target.dataset.sizes;
                }
            });
        }
    }, [entry.isIntersecting, entry.target]);

    const imageProps = {
        src: placeholder || src,
        'data-sizes': sizes,
        'data-src-set': srcSet,
        'data-src': src,
        ref,
    };

    return <Img key={src} loading="lazy" alt={alt} {...rest} {...imageProps} />;
};

const Image = ({ alt, background, format, sizes, src, placeholder, quality, loading, scale, ...rest }) => {
    sizes = generateSizes(sizes);

    let srcSet = null;

    let placeholderSrc = placeholder || src;

    if (isObject(src)) {
        let { url } = src;
        if (url.indexOf('?') > -1) {
            const [baseUrl] = url.split('?');
            url = baseUrl;
        }

        placeholderSrc = placeholder || url;
        const widths = asArray(src.width);
        const heights = asArray(src.height);

        // @todo:  remove f: Format, only here not to break storm urls that uses this.
        src = generateSrc(url, {
            auto: srcIsPlaceholder(src) ? 'format,compress' : 'format',
            f: format,
            bg: background,
            q: quality,
            w: widths[widths.length - 1],
            h: heights[widths.length - 1],
            scale,
        });

        if (widths.length > 1) {
            srcSet = generateSrcSet(src, widths, heights);
        }

        placeholderSrc = generateSrc(placeholderSrc, {
            auto: srcIsPlaceholder(placeholderSrc) ? 'format,compress' : 'format',
            f: format,
            bg: background,
            q: quality,
            w: widths[0],
            h: heights[0],
            scale,
        });
    }

    const Component = loading === 'lazy' ? LazyImage : Img;

    return <Component {...rest} placeholder={placeholderSrc} alt={alt} src={src} srcSet={srcSet} sizes={sizes} />;
};

Image.propTypes = {
    alt: PropTypes.string,
    background: PropTypes.string,
    format: PropTypes.oneOf(['webp', 'jpg', 'png']),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    loading: PropTypes.string,
    objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
    placeholder: PropTypes.string,
    quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    scale: PropTypes.string,
    sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
            height: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        }),
    ]).isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

Image.defaultProps = {
    alt: 'Image title missing',
    background: null,
    format: 'jpg',
    height: null,
    loading: 'lazy',
    objectFit: null,
    placeholder: null,
    quality: '70',
    scale: null,
    sizes: null,
    width: '100%',
};

LazyImage.propTypes = Image.propTypes;
LazyImage.defaultProps = Image.defaultProps;

export default Image;
