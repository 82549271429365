import { SET_TOTAL_PRICE, UPDATE_LOCK } from './constants';

const initialState = {
    isLocked: false,
    totalPrice: null,
};

export { initialState };

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_LOCK:
            return {
                ...state,
                isLocked: action.lock,
            };
        case SET_TOTAL_PRICE:
            return {
                ...state,
                totalPrice: action.totalPrice,
            };
        default:
            return state;
    }
};
