/**
 * Scroll lib to prevent scroll on all browsers.
 * Replacement for preventScroll in GC's application model.
 *
 * @author Timmie Sarjanen
 * @version 1.0.1
 */

const DATA_ATTR = 'data-prevent-scroll';

const isBrowser = typeof window !== 'undefined';
const isIOS = isBrowser && window.CSS && CSS.supports('-webkit-overflow-scrolling: touch');

let currentPath = '';
const getPathName = () => (isBrowser ? window.location.pathname : '');
const setPathName = () => {
    currentPath = getPathName();
};

export const isScrollAllowed = () => document.body.getAttribute(DATA_ATTR) === null;

export const preventScroll = () => {
    if (isBrowser && isScrollAllowed()) {
        const { body } = document;
        const scrollTop = window.pageYOffset;
        const scrollbarGap = window.innerWidth - document.documentElement.clientWidth;

        body.setAttribute(DATA_ATTR, 'true');

        if (isIOS) {
            setPathName();
            if (scrollTop) {
                body.style.top = `-${scrollTop}px`;
            }
            body.style.position = 'fixed';
            body.style.width = '100%';
        }

        body.style.overflow = 'hidden';

        if (scrollbarGap > 0) {
            body.style.paddingRight = `${scrollbarGap}px`;
        }
    }
};

export const allowScroll = () => {
    if (isBrowser && !isScrollAllowed()) {
        const { body } = document;
        const scrollTop = body.style.top ? Math.abs(parseInt(body.style.top, 10)) : 0;

        body.removeAttribute('style');
        body.removeAttribute(DATA_ATTR);

        if (isIOS && scrollTop) {
            // iOS 13 bug where allowScroll fires after a page transition
            if (currentPath === getPathName()) {
                window.scrollTo(0, scrollTop);
            } else {
                window.scrollTo(0, -100);
            }
        }
    }
};

export const scrollTo = ({ smooth = true, left = 0, top = 0 }) => {
    if (isBrowser) {
        if (document.documentElement && 'scrollBehavior' in document.documentElement.style) {
            window.scrollTo({
                behavior: smooth !== false ? 'smooth' : 'auto',
                left,
                top,
            });
        } else {
            window.scrollTo(left, top);
        }
    }
};

export default {
    allowScroll,
    isScrollAllowed,
    preventScroll,
    scrollTo,
};
