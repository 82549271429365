import { connect } from 'react-redux';
import models from './models';
import store from './store';

const inject = (modelName, state) => {
    const props = state[modelName];
    const model = models[modelName];
    const modelKeys = Object.keys(model);

    modelKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(model, key)) {
            props[key] = model[key];
        }
    });

    // for (const i in models[modelName]) {
    //     console.log({ i });
    //     if (models[modelName].hasOwnProperty(i)) {
    //         props[i] = models[modelName][i];
    //     }
    // }

    return props;
};

export const getModel = (modelName) => {
    if (!models[modelName]) {
        return null;
    }

    return inject(modelName, store.getState());
};

export const injectModel = (modelName) => (Component) => {
    const mapStateToProps = (state, ownProps) => {
        const props = {};

        props[modelName] = inject(modelName, state);

        return props;
    };

    return connect(mapStateToProps)(Component);
};

export const injectModels = (modelNames) => (Component) => {
    const mapStateToProps = (state, ownProps) => {
        const props = {};
        modelNames.forEach((modelName) => {
            props[modelName] = inject(modelName, state);
        });

        return props;
    };

    return connect(mapStateToProps)(Component);
};

export const getState = (model = null) => {
    const state = store.getState();

    if (model && state[model]) {
        return state[model];
    }

    return state;
};

const locks = {};

export const acquireMutex = (mutex) => {
    if (typeof mutex === 'string') {
        // It's a single lock.
        let mutexResolver = null;
        const mutexPromise = new Promise((resolve) => {
            mutexResolver = resolve;
        });

        if (!locks[mutex]) {
            locks[mutex] = [];
        }

        locks[mutex].push(mutexPromise);

        if (locks[mutex].length > 1) {
            // Mutex exist.
            return locks[mutex][locks[mutex].length - 2].then(() => mutexResolver);
        }

        return mutexResolver;
    }

    // else if (typeof mutex === 'object' && locks.length && locks.length > 0) {
    //     // It's an array.
    //     // @todo: Implement multiple mutexes.
    // }
};
