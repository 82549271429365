import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import { above } from 'core-web/util/mediaqueries';

const Wrapper = styled('div')`
    padding-bottom: 48px;

    padding: 0px;

    ${above.md} {
        padding: 16px 16px 16px;
    }

    & > div:last-of-type {
        margin-bottom: 0;
    }
`;

const ContentModuleWrapper = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>;

ContentModuleWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ContentModuleWrapper;
