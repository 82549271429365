import GTM from '@grebban/gtm';
import tracking from 'core-web/components/Tracking';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import getPageProductCategories from 'core-web/util/getPageProductCategories';
import { getUUID } from 'core-web/util/getUUID';

export default async (data) => {
    const { application, page } = data;

    const algoliaUserToken = getUUID();

    const pageId = page.data.id;
    let pageTitle = '404';

    if (page.data.title) {
        pageTitle = page.data.title;
    } else if (page.data.default_name) {
        pageTitle = page.data.default_name;
    }

    const salesforceId = tracking.getSalesforceTrackingKey();

    const categoryIds = await getPageProductCategories(page);

    GTM.addDataLayerEvent('page_view', {
        algoliaUserToken,
        currency: application.currency.code,
        // site_id: application.applicationId,
        page_id: pageId || '',
        page_title: pageTitle,
        page_title_decoded: decodeHTMLEntities(pageTitle),
        page_type: page.type,
        page_template: page.template,
        subscriberID: salesforceId || '',
        categoryIds: categoryIds.join(','),
    });
};
