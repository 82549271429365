import { format } from 'date-fns';
import { sv, fi, de } from 'date-fns/locale';

const formatDate = (date: number | Date, dateFormat = 'yyyy-MM-dd', locale = 'sv_SE') => {
    if (!date) {
        return null;
    }

    const options = {};
    const isoLocale = (locale || 'sv_SE').slice(0, 2);
    const dateLocales = { sv, fi, de };
    const dateLocale = dateLocales[isoLocale];
    if (dateLocale) {
        options.locale = dateLocale;
    }

    return format(date, dateFormat, options);
};

export default formatDate;
