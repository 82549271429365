import { API_ENDPOINT, API_URL, inServer } from 'core-web/constants';
import { getModel } from 'core-web/state';
import buildQueryString from './buildQueryString';

const getHeaders = (extra = {}) => {
    const headers = inServer ? {} : new Headers();

    if (inServer) {
        headers.Accept = 'application/json';
    } else {
        headers.append('Accept', 'application/json');
    }

    if (process.env.NODE_ENV === 'development') {
        headers['Cache-Control'] = 'no-cache';
    } else {
        // Don't set this right now.
        // headers.append('Cache-Control', 'cache');
    }

    Object.keys(extra).forEach((key) => {
        if (inServer) {
            headers[key] = extra[key];
        } else {
            headers.append(key, extra[key]);
        }
    });

    return headers;
};

const getBaseUrl = () => {
    const { origin, protocol } = getModel('application');
    return API_URL || protocol + origin + API_ENDPOINT;
};

/**
 * @typedef {Object} FetchOptions
 * @property {('GET'|'POST'|'PUT'|'DELETE')} method
 * @property {String} path Relative path
 * @property {Object} data Request body / query options
 * @property {String} baseUrl Base URL. Defaults to `REACT_APP_GREBBCOMMERCE_API_URL`
 *
 * @param {FetchOptions}
 */
export const request = async ({ method, path, data, baseUrl }) => {
    if (!baseUrl) {
        baseUrl = getBaseUrl();
    }

    let url = baseUrl + path;

    /**
     * {RequestInit}
     */
    const fetchOpts = {
        method,
        credentials: 'include', // include, *same-origin, omit
        // headers: { Origin: getOrigin() }, // NVTODO: Get origin from some oth
    };

    if (method === 'GET') {
        fetchOpts.headers = getHeaders();

        if (data) {
            const prefix = url.indexOf('?') > -1 ? '&' : '?';
            const queryString = buildQueryString(data);

            url += prefix + queryString;
        }
    } else {
        fetchOpts.headers = getHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        });
        fetchOpts.body = buildQueryString(data);
    }

    return fetch(url, fetchOpts)
        .then((response) => {
            if (response && response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType === null) {
                    return new Promise((resolve) => {
                        resolve(null);
                    });
                }
                if (contentType.startsWith('application/json')) {
                    return response.json();
                }
                if (contentType.startsWith('text/plain')) {
                    return response.text();
                }
                return `Unsupported response content-type: ${contentType}`;
                // return response.json();
            }
            throw response;
        })
        .catch((response) => {
            /*
            console.error(
                `Unable to fetch from GrebbCommerce API`,
                {
                    method,
                    baseUrl,
                    path,
                    url,
                    data,
                    fetchOpts,
                    response
                }
            );
            */
            throw response;
        });
};

export const get = (path, data) =>
    request({
        method: 'GET',
        path,
        data,
    });

export const post = (path, data) =>
    request({
        method: 'POST',
        path,
        data,
    });

export const put = (path, data) =>
    request({
        method: 'PUT',
        data,
        path,
    });

export const del = (path, data) =>
    request({
        method: 'DELETE',
        data,
        path,
    });
