import { NEWSLETTER_SUBSCRIBE, NEWSLETTER_SUBSCRIBE_ERROR, NEWSLETTER_SUBSCRIBE_SUCCESS } from './constants';

// The initial state of this store.
// @todo: fix what kind of initial states we need, besides
// isFetching and stuff (added isSubscribed to start with?)
const initialState = {
    error: '',
    isFetching: false,
    isSubscribed: false,
};

export { initialState };

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case NEWSLETTER_SUBSCRIBE:
            return {
                ...state,
                isFetching: true,
            };
        case NEWSLETTER_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isSubscribed: true,
            };
        case NEWSLETTER_SUBSCRIBE_ERROR:
            return {
                ...state,
                error: action.error,
                isFetching: false,
            };
        default:
            return state;
    }
};
