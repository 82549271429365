import React from 'react';
import loadable from '@loadable/component';
import Loading from 'theme/components/Loading';

const options = {
    fallback: <Loading />,
};

export default {
    default: loadable(() => import('./Default'), options),
    checkout: loadable(() => import('./Checkout'), options),
    'checkout-sales': loadable(() => import('./CheckoutAuth'), options),
    'checkout-thanks': loadable(() => import('./order-confirm/OrderConfirmation'), options),
    'checkout-auth': loadable(() => import('./CheckoutAuth'), options),
    frontpage: loadable(() => import('./FrontPage'), options),
    contact: loadable(() => import('./ContactAbout'), options),
    faq: loadable(() => import('./FAQ'), options),
    contentpage: loadable(() => import('./Content'), options),
    policy: loadable(() => import('./Policy'), options),
    search: loadable(() => import('./SearchPage'), options),
    article_landingpage: loadable(() => import('./Articles'), options),
    compare_page: loadable(() => import('./ComparePage'), options),
    product_guide: loadable(() => import('./ProductGuide'), options),
    parts_page: loadable(() => import('./ManufacturerParts'), options),
    brands: loadable(() => import('./Brands'), options),
    basket_share: loadable(() => import('./BasketShare'), options),
    cookie_list_page: loadable(() => import('core-web/views/Page/CookieListPage'), options),
};
