import { MANUAL_ORDER_HANDLING } from 'theme/config/constants';

export const checkIfManualOrderItem = (items) => {
    if (!items) {
        return false;
    }

    const shouldHandleOrderManually = (item) => item.id === 1697;
    return items.some((item) => item.format.flags.find(shouldHandleOrderManually));
};

// Check if there is a product in basket that should be handled manually
export const checkIfHandleProductManually = (items) => {
    const shouldHandleManually = checkIfManualOrderItem(items);
    return {
        infos: [
            {
                code: MANUAL_ORDER_HANDLING,
                value: shouldHandleManually ? 'True' : '',
            },
        ],
    };
};

export const shouldUpdateManualOrderInfo = (basket, items) => {
    const manualOrderFlag = basket.info.find((i) => i.code === MANUAL_ORDER_HANDLING);
    const shouldBeHandledManualy = checkIfManualOrderItem(items);

    return (
        (manualOrderFlag.value === '' && shouldBeHandledManualy) ||
        (manualOrderFlag.value !== '' && !shouldBeHandledManualy)
    );
};
