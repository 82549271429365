import Cookies from 'js-cookie';
import {
    BasketEvents,
    CheckoutEvents,
    LogEvents,
    PageEvents,
    ProductEvents,
    PromotionEvents,
    SaleEvents,
    SearchEvents,
} from 'core-web/libs/Events/constants';
import { getState } from 'core-web/state';
import getCountrySpecificVariable from 'core-web/util/getCountrySpecificVariable';
// Basket
import BasketProductAdded from './BasketProductAdded';
import BasketProductRemoved from './BasketProductRemoved';
import CheckoutPayment from './CheckoutPayment';
import CheckoutShipping from './CheckoutShipping';
// Checkout
import CheckoutView from './CheckoutView';
import ExactCategoryMatch from './ExactCategoryMatch';
// Error
import Log from './Log';
import NotFound from './NotFound';
import PageView from './PageView';
import ProductClick from './ProductClick';
import ProductImpressions from './ProductImpressions';
// Product
import ProductView from './ProductView';
import PromotionClick from './PromotionClick';
// Promotion
import PromotionImpressions from './PromotionImpressions';
import Refund from './Refund';
// Search
import Search from './Search';
import Transaction from './Transaction';
// Sales
import UpsellProductAdded from './UpsellProductAdded';
import { getConsentCategories, getOneTrustConsent } from './utils/getOneTrustConsent';

export default {
    getClientId() {
        try {
            const { performanceConsent } = getConsentCategories();
            if (!performanceConsent) {
                return false;
            }

            const gaCookie = Cookies.get('_ga');
            return `${gaCookie.split('.')[2]}.${gaCookie.split('.')[3]}`;
        } catch (e) {
            return false;
        }
    },

    getSessionId() {
        try {
            const { performanceConsent } = getConsentCategories();
            if (!performanceConsent) {
                return false;
            }

            const locale = getState('application')?.locale;
            const cookieName = `_ga_${getCountrySpecificVariable('REACT_APP_GA_MEASUREMENT_ID', locale).split('-')[1]}`;
            const gaIdValue = Cookies.get(cookieName);
            return gaIdValue.split('.')[2];
        } catch (e) {
            return false;
        }
    },

    getSalesforceTrackingKey() {
        try {
            const { targetingConsent } = getConsentCategories();
            if (!targetingConsent) {
                return false;
            }

            return Cookies.get('sfmc_id');
        } catch (error) {
            return false;
        }
    },

    getGenericSessionId() {
        try {
            const { performanceConsent } = getConsentCategories();
            if (!performanceConsent) {
                return false;
            }

            const gaIdValue = Cookies.get(`_ga_12345ABCDE`); // Fake ID primarly used for server tracking
            return gaIdValue.split('.')[2];
        } catch (e) {
            return false;
        }
    },

    getTrackingData() {
        const clientId = this.getClientId();
        const sessionId = this.getSessionId();
        const sessionIdGeneric = this.getGenericSessionId();
        const salesforceKey = this.getSalesforceTrackingKey();
        const consentGroups = getOneTrustConsent();
        const timeStamp = Math.round(new Date().getTime() / 1000);

        const data = {};
        if (clientId) {
            data.client_id = clientId;
        }
        if (sessionId) {
            data.session_id = sessionId;
        }
        if (sessionIdGeneric) {
            data.session_id_generic = sessionIdGeneric;
        }
        if (salesforceKey) {
            data.subscriber_id = salesforceKey;
        }
        if (consentGroups) {
            data.consent = consentGroups;
        }
        data.tracking_event_timestamp = timeStamp;

        return data;
    },

    trackingEvents() {
        return [
            // Page
            { name: PageEvents.VIEW, callback: PageView, handle: null },
            { name: PageEvents.NOT_FOUND, callback: NotFound, handle: null },

            // Logs including errors
            { name: LogEvents.LOG, callback: Log, handle: null },
            { name: BasketEvents.PRODUCTS_ONHAND_CHANGE, callback: Log, handle: null },

            // Basket
            { name: BasketEvents.PRODUCT_ADDED, callback: BasketProductAdded, handle: null },
            { name: BasketEvents.PRODUCT_REMOVED, callback: BasketProductRemoved, handle: null },

            // Upsell
            { name: SaleEvents.UPSELL_PRODUCT_ADDED, callback: UpsellProductAdded, handle: null },

            // Promotion
            { name: PromotionEvents.CLICK, callback: PromotionClick, handle: null },
            { name: PromotionEvents.IMPRESSIONS, callback: PromotionImpressions, handle: null },

            // Search
            { name: SearchEvents.SUBMITTED, callback: Search, handle: null },
            { name: SearchEvents.EXACT_CATEGORY_MATCH, callback: ExactCategoryMatch, handle: null },

            // Product
            { name: ProductEvents.VIEW, callback: ProductView, handle: null },
            { name: ProductEvents.CLICK, callback: ProductClick, handle: null },
            { name: ProductEvents.IMPRESSIONS, callback: ProductImpressions, handle: null },

            // Checkout
            { name: CheckoutEvents.VIEW, callback: CheckoutView, handle: null },
            { name: CheckoutEvents.SHIPPING, callback: CheckoutShipping, handle: null },
            { name: CheckoutEvents.PAYMENT, callback: CheckoutPayment, handle: null },
            { name: CheckoutEvents.TRANSACTION, callback: Transaction, handle: null },
            { name: CheckoutEvents.REFUND, callback: Refund, handle: null },
        ];
    },
};
