import { Search } from 'core-web/libs/Algolia-v2';
import { getModel } from 'core-web/state';
import { uniqueArrayValues } from 'core-web/util/array';

const search = async (productIds, index) => {
    try {
        const locale = getModel('application').locale.toLowerCase();
        const response = await Search(`${index}_${locale}`, '', {
            filters: [productIds.map((id) => `objectID:"${id}"`)].filter((i) => i.length !== 0),
        });
        return response.results[0].hits;
    } catch (error) {
        return [];
    }
};

const getProductCategories = async (productIds) => {
    if (Array.isArray(productIds) && productIds.length) {
        const products = await search(productIds, 'products');
        return products.map((product) => `${product.categoryPrimary}`);
    }
    return [];
};

const getPageProductCategories = async (page) => {
    try {
        const appName = process.env.REACT_APP_NAME || '';
        const pageData = page?.data;
        const pageContent = pageData?.page_content;
        const { type } = page;

        if (type === 'product_category') {
            const grid = pageContent.find((content) => content.layout === 'product_grid');
            const gridCategories = grid?.data?.product_selection?.categories;
            if (grid?.data && Array.isArray(gridCategories)) {
                return gridCategories;
            }
        }

        if (type === 'post') {
            const manualCategoryIds = pageData.category_code && pageData.category_code.split(/\D/);
            let productIds = [];

            if (appName === 'maskinklippet' || appName === 'dogger' || appName === 'frishop') {
                productIds = page?.data?.article_products;
            } else if (appName === 'duab') {
                productIds = pageData?.settings?.recommended_products;
            } else if (appName === 'hylte') {
                const articleProducts = pageData?.article_products;
                if (Array.isArray(articleProducts) && articleProducts.length) {
                    productIds = articleProducts[0]?.products;
                }
            }

            const categories = await getProductCategories(productIds);

            if (manualCategoryIds) {
                categories.push(...manualCategoryIds);
            }

            return uniqueArrayValues(categories);
        }

        return [];
    } catch (error) {
        return [];
    }
};

export default getPageProductCategories;
