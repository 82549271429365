import cloneDeep from 'core-web/util/cloneDeep';
import { getFlag } from 'core-web/util/getFlag';
import getProductStockStatus, { getPackageStockStatus } from 'core-web/util/getProductStockStatus';
import { PRODUCT_BACKORDER, PRODUCT_PREORDER } from 'theme/config/constants';

export const getProductAvailability = (product, locale) => {
    const {
        format: { flags },
        on_hand: onHand,
        on_hand_store: onHandStore,
        included_products: includedProducts,
    } = product;

    let stockStatus;

    if (includedProducts?.length) {
        // package product
        [stockStatus] = getPackageStockStatus(includedProducts, locale);
    } else {
        // non-package
        stockStatus = getProductStockStatus([onHand, onHandStore], locale);
    }

    if (stockStatus.stock > 0) {
        return { inStock: true };
    }
    if (stockStatus.nextDelivery) {
        if (getFlag(PRODUCT_PREORDER, flags)) {
            return { preOrder: true, nextDelivery: stockStatus.nextDelivery };
        }
        if (getFlag(PRODUCT_BACKORDER, flags)) {
            return { backOrder: true, nextDelivery: stockStatus.nextDelivery };
        }
    }

    return { inStock: false };
};

export const getAvailabilitySchemaUrl = (productAvailability) => {
    if (productAvailability.inStock) {
        return 'http://schema.org/InStock';
    }

    if (productAvailability.preOrder) {
        return 'https://schema.org/PreOrder';
    }

    if (productAvailability.backOrder) {
        return 'https://schema.org/BackOrder';
    }

    return 'http://schema.org/OutOfStock';
};

export const getBasketItemAvailability = (product, locale) => {
    const { quantity } = product;
    const { flags } = product.format;
    const onHand = product.on_hand;
    const onHandStore = product.on_hand_store;

    // Unsure of how exactly HYMA wants to handle packages, seems like HYMA isn't sure yet either?
    // Starting out assuming they put flags on the package-product and not included products.
    const stockStatus = getProductStockStatus([onHand, onHandStore], locale);

    if (quantity <= stockStatus.stock) {
        return { isOk: true };
    }

    // More in basket than in stock

    const inStockProduct = cloneDeep(product);
    const backorderProduct = cloneDeep(product);

    if (getFlag([PRODUCT_PREORDER, PRODUCT_BACKORDER], flags)) {
        const restItems = quantity - stockStatus.stock;
        const inStockItems = stockStatus.stock;

        backorderProduct.specificItemQuantity = restItems;

        backorderProduct.on_hand_store.value = 0;
        backorderProduct.on_hand.value = 0;
        backorderProduct.on_hand.incoming_value = 0;
        backorderProduct.on_hand.nextDelivery = stockStatus.nextDelivery;

        backorderProduct.inStockItems = inStockItems;
        backorderProduct.maxLength = 100;
        backorderProduct.isBackorderProduct = true;

        inStockProduct.specificItemQuantity = inStockItems;
        inStockProduct.inStockItems = inStockItems;
        inStockProduct.maxLength = inStockItems;
        inStockProduct.on_hand.nextDelivery = stockStatus.nextDelivery;

        return {
            partNo: product.part_no,
            backorderProduct,
            inStockProduct: inStockItems > 0 ? inStockProduct : null,
            isOk: false,
            bookedItems: restItems,
            inStockItems,
            totalProductQuantity: inStockItems + restItems,
        };
    }

    return { isOk: true };
};
