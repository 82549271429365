import GTM from '@grebban/gtm';
import UpdateProductViewCount from 'core-web/libs/GrebbCommerceAPI/Products/UpdateProductViewCount';
import { getModel } from 'core-web/state';
import tracking from 'core-web/components/Tracking';
import { getProduct } from './utils/product';

export default (data) => {
    const product = data?.data;

    if (!product) {
        return;
    }

    const application = getModel('application');
    const { basketId } = getModel('basket') || {};
    const salesforceId = tracking.getSalesforceTrackingKey();

    GTM.addDataLayerEvent('product_view', {
        algoliaDisplayedObjectIDs: product.part_no,
        ecommerce: {
            basket: { id: basketId },
            detail: {
                products: [getProduct(product)],
            },
        },
        currency: application.currency.code,
        subscriberID: salesforceId || '',
    });

    // Track product view in API
    if (data?.data?.id && data?.data?.category_id) {
        UpdateProductViewCount(data.data.id, data.data.category_id);
    }
};
