import tracking from 'core-web/components/Tracking';
import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, items) => {
    const trackingData = tracking.getTrackingData();

    const data = {
        application_id: getModel('application').applicationId,
        items,
        ...trackingData,
    };

    return post(`/frontend/basket/${basketId}/items/create`, data);
};
