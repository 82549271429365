import { post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketID, parameters = {}, returnDeliveryPoints = false, returnDeliveryMethods = false) =>
    post(`/frontend/checkout/${basketID}/delivery`, {
        application_id: getModel('application').applicationId,
        return_delivery_methods: returnDeliveryMethods,
        return_delivery_points: returnDeliveryPoints,
        ...parameters,
    });
