import { inServer } from 'core-web/constants';
import { hashString } from 'core-web/util/hashString';
import { base64Encode } from 'core-web/util/string';

interface SSRCache {
    get: (key: string) => unknown;
    set: (key: string, value: unknown, ttl: number) => void;
    has: (key: string) => boolean;
}

const second = 1000;
const minute = second * 60;

export const cacheKey = (...value: unknown[]): Promise<string> => {
    const stringValue = JSON.stringify(value);

    // `hashString` uses crypto which is not available without SSL
    if (['qa', 'production'].indexOf(process.env.REACT_APP_ENV || 'production') === -1) {
        return Promise.resolve(base64Encode(stringValue));
    }

    return hashString(stringValue, 'SHA-1');
};

export const cache = async <T extends (...args: unknown[]) => Promise<unknown>, R = Awaited<ReturnType<T>>>(
    closure: T,
    key: string,
    ttl = minute,
): Promise<R> => {
    const extendedGlobal = global as unknown as { ssrCache: SSRCache };

    if (!inServer || typeof extendedGlobal.ssrCache === 'undefined') {
        return closure() as R;
    }

    if (extendedGlobal.ssrCache.has(key)) {
        return extendedGlobal.ssrCache.get(key) as R;
    }

    const response = await closure();
    // todo check response size to prevent massive requests being stored
    extendedGlobal.ssrCache.set(key, response, ttl);

    return response as R;
};
