import Events from 'core-web/libs/Events';
import { ScrollEvents } from 'core-web/libs/Events/constants';
import scroll from 'core-web/libs/scroll';
import { injectModel } from 'core-web/state';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollRestoration extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.prevScroll = 0;
        this.history = [];

        this.events = [];
    }

    componentDidMount() {
        const handleWindowResize = debounce(this.handleWindowResize, 500);
        window.addEventListener('resize', handleWindowResize);
        ScrollRestoration.setScrollRestoration('manual');

        this.events[ScrollEvents.TO_TOP] = Events.subscribe(ScrollEvents.TO_TOP, () =>
            scroll.scrollTo({ smooth: false, left: 0, top: 0 }),
        );
    }

    shouldComponentUpdate(nextProps) {
        const { location, page } = this.props;
        const body = document && document.body ? document.body : false;

        if (location.pathname !== nextProps.location.pathname) {
            this.addScrollHistoryObj(location.pathname);
        }

        if (nextProps.page.type === 'product_category' && body) {
            body.style.overflowAnchor = 'none';
        } else {
            body.style.overflowAnchor = '';
        }

        if (nextProps.page.type === 'product_category' && location.search !== nextProps.location.search) {
            return true;
        }

        return nextProps.page.isFetching !== page.isFetching;
    }

    componentDidUpdate() {
        const { location, page } = this.props;
        const scrollObj = this.getScrollHistoryObj(location.pathname);
        const scrollY = scrollObj && scrollObj.y && scrollObj.y;
        const scrollHeight = scrollObj && scrollObj.height;
        const thisPage = page;

        document.body.style.minHeight = scrollHeight ? `${scrollHeight}px` : '0px';

        if (!thisPage.isFetching) {
            scroll.scrollTo({ smooth: false, left: 0, top: scrollY || 0 });
            this.removeScrollHistoryObj(location.pathname);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        ScrollRestoration.setScrollRestoration('auto');
        Events.unsubscribe(this.events[ScrollEvents.TO_TOP]);
    }

    getScrollHistoryObj = (path) => {
        const matches = this.history.filter((obj) => obj.path === path);
        return matches[0] ? matches[0] : false;
    };

    static setScrollRestoration = (value) => {
        if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = value;
        }
    };

    removeScrollHistoryObj = (path) => {
        const matches = this.history.findIndex((obj) => obj.path === path);
        // The index of the first element in the array that passes the test. Otherwise, -1.
        if (matches > -1) {
            this.history.splice(matches, 1);
        }
    };

    addScrollHistoryObj = (path) => {
        const y = window.pageYOffset || document.documentElement.scrollTop;
        const height = document.body.scrollHeight;

        if (path) {
            this.history.unshift({ path, height, y });
        }

        if (this.history.length > 5) {
            this.history.length = 5;
        }
    };

    handleWindowResize = () => {
        const { location } = this.props;
        const scrollObj = this.history[location.path] || 0;
        document.body.style.minHeight = this.prevScroll !== scrollObj.y && '0';
        this.prevScroll = scrollObj.y;
    };

    render() {
        return null;
    }
}

export default withRouter(injectModel('page')(ScrollRestoration));
