import { inServer } from 'core-web/constants';

let UUID;

export const getUUID = () => {
    if (UUID) {
        return UUID;
    }

    if (!inServer) {
        UUID = window.localStorage.getItem('algolia-user-token');

        if (!UUID) {
            const [value] = crypto.getRandomValues(new Uint32Array(1));
            UUID = value;
            window.localStorage.setItem('algolia-user-token', UUID);
        }

        return UUID;
    }
};
