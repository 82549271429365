import GTM from '@grebban/gtm';

export default async (data) => {
    // Include products if partial refund
    GTM.addDataLayerEvent({
        ecommerce: {
            refund: {
                actionField: { id: 'Order ID' },
                // products: [
                //     {id: 'P4567', quantity: 1},   // Product ID and quantity. Required for partial refunds.
                //     {id: 'P8901',quantity: 2}
                // ]
            },
        },
    });
};
