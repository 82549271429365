const views = {
    // Add the views that exists in core-web/views directly in here.
    // We don't want to dynamically import them as that would case circular references.
};

try {
    // eslint-disable-next-line global-require -- Needed to dynamically import the theme views.
    const themeViews = require('theme/views').default;

    const themeViewEntries = Object.entries(themeViews);

    themeViewEntries.forEach(([key, value]) => {
        if (views[key] === undefined) {
            views[key] = value;
        }
    });
} catch (e) {
    // Do nothing.
}

export default views;
