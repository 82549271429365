import store from 'core-web/state/store';
import {
    getProductsWithFacets,
    getCmsQuery,
    getProductsByObjectId,
    addCompareProduct,
    removeCompareProduct,
    removeAllComparedProducts,
    updateCompareProducts,
    getCompareProducts,
    search,
    setVat,
    toggleVat,
    setShowVat,
    setAllProducts,
    setNextFetchMode,
    setCategoryTree,
} from './actions';

export { default as ProductsReducers } from './reducers';

export default {
    getProductsWithFacets: (cmsQuery) => store.dispatch(getProductsWithFacets(cmsQuery)),
    getCmsQuery: (cmsData, extra) => getCmsQuery(cmsData, extra),
    addCompareProduct: (objectId) => store.dispatch(addCompareProduct(objectId)),
    removeCompareProduct: (objectId) => store.dispatch(removeCompareProduct(objectId)),
    removeAllComparedProducts: () => store.dispatch(removeAllComparedProducts()),
    updateCompareProducts: (products) => store.dispatch(updateCompareProducts(products)),
    getCompareProducts: () => store.dispatch(getCompareProducts()),
    getProductsByObjectId: (objectID) => getProductsByObjectId(objectID),
    search: (query, parameters = {}) => {
        const { application } = store.getState();

        const paginationState = store.getState().products.pagination;

        return store.dispatch(search(application.locale, query, parameters, paginationState));
    },
    setVat: () => store.dispatch(setVat()),
    toggleVat: () => store.dispatch(toggleVat()),
    setShowVat: (value) => store.dispatch(setShowVat(value)),
    setAllProducts: (products) => store.dispatch(setAllProducts(products)),
    setNextFetchMode: (mode) => store.dispatch(setNextFetchMode(mode)),
    setCategoryTree: (withDispatch = true) => store.dispatch(setCategoryTree(withDispatch)),
};
