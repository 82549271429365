// eslint-disable-next-line eslint-comments/disable-enable-pair -- ??
/* eslint-disable sonarjs/no-duplicated-branches -- @TODO: remove redundant cases */
import Cookies from 'js-cookie';
import {
    // Filters.
    APPLY_FILTER,
    // Sort
    APPLY_SORT,
    CLEAR_FILTER,
    CLEAR_FILTERS,
    CLEAR_SORT,
    // Get Category Products
    GET_CATEGORY_PRODUCTS,
    GET_CATEGORY_PRODUCTS_ERROR,
    GET_CATEGORY_PRODUCTS_SUCCESS,
    // Get Products
    GET_PRODUCTS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCTS_SUCCESS,
    REMOVE_FILTER,
    // Page dependencies
    RESOLVE_PAGE_DEPENDENCIES,
    RESOLVE_PAGE_DEPENDENCIES_ERROR,
    RESOLVE_PAGE_DEPENDENCIES_SUCCESS,
    // Search.
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_ERROR,
    SEARCH_PRODUCTS_SUCCESS,
    // Set All Products
    SET_ALL_PRODUCTS,
    SET_ALL_PRODUCTS_ERROR,
    SET_ALL_PRODUCTS_SUCCESS,
    // Set Category Tree
    SET_CATEGORY_TREE,
    SET_CATEGORY_TREE_ERROR,
    SET_CATEGORY_TREE_SUCCESS,
    // Set Compare Products
    SET_COMPARE_PRODUCTS,
    SET_COMPARE_PRODUCTS_ERROR,
    SET_COMPARE_PRODUCTS_SUCCESS,
    // Set Last Compare Product
    SET_COMPARE_PRODUCT_LAST,
    SET_COMPARE_PRODUCT_LAST_ERROR,
    SET_COMPARE_PRODUCT_LAST_SUCCESS,
    SET_NEXT_FETCH_MODE,
    // VAT
    SHOW_VAT,
} from './constants';

// The initial state of this store.
const initialState = {
    products: [],
    allProducts: [],
    fetchMode: 'replace',
    pagination: {
        page: 0,
        pageSize: 12,
        pageCount: null,
        hitsCount: 0,
    },
    isFetching: false,
    appliedFilters: {},
    appliedSort: null,
    compareProducts: Cookies.get('compare_products') ? JSON.parse(Cookies.get('compare_products')) : [],
    lastCompareProduct: null,
    showVat: Cookies.get('show_vat') ? Cookies.get('show_vat') === 'true' : true,
    algolia: {},
    categoryTree: [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_PRODUCTS: {
            return {
                ...state,
                isFetching: true,
            };
        }
        case GET_PRODUCTS_SUCCESS: {
            // This probably needs some functionality to merge the current productsstate with the fetched one,
            // so that we just don't replace them all.
            // If the fetched one exist, update it otherwise insert it.

            // This was an attempt to insert the products into the state with the product id as the key.
            // Not sure if that's even needed.
            // Will be looked at if it does.
            /* let products = {};
            action.products.forEach(product => {
                products[product.product] = product;
            }); */
            return {
                ...state,
                products: action.products,
                isFetching: false,
            };
        }

        case SET_ALL_PRODUCTS_SUCCESS: {
            const { fetchMode } = state;
            let allProducts = null;

            if (fetchMode === 'add') {
                allProducts = state.allProducts.concat(action.products);
            } else if (fetchMode === 'replace') {
                allProducts = action.products;
            } else {
                allProducts = state.allProducts;
            }

            return {
                ...state,
                allProducts,
            };
        }

        case SET_NEXT_FETCH_MODE: {
            return {
                ...state,
                fetchMode: action.mode,
            };
        }

        // Set Compare Products
        case SET_COMPARE_PRODUCTS:
            return {
                ...state,
                isFetching: true,
            };
        case SET_COMPARE_PRODUCTS_SUCCESS:
            return {
                ...state,
                compareProducts: action.compareProducts,
                isFetching: false,
            };
        case SET_COMPARE_PRODUCTS_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Set Last Compare Product
        case SET_COMPARE_PRODUCT_LAST:
            return {
                ...state,
                isFetching: true,
            };
        case SET_COMPARE_PRODUCT_LAST_SUCCESS:
            return {
                ...state,
                lastCompareProduct: action.lastCompareProduct,
                isFetching: false,
            };
        case SET_COMPARE_PRODUCT_LAST_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        case GET_CATEGORY_PRODUCTS: {
            return {
                ...state,
                isFetching: true,
            };
        }
        case GET_CATEGORY_PRODUCTS_SUCCESS: {
            // This probably needs some functionality to merge the current productsstate with the fetched one,
            // so that we just don't replace them all.
            // If the fetched one exist, update it otherwise insert it.

            // This was an attempt to insert the products into the state with the product id as the key.
            // Not sure if that's even needed.
            // Will be looked at if it does.
            /* let products = {};
            action.products.forEach(product => {
                products[product.product] = product;
            }); */
            return {
                ...state,
                products: action.products,
                isFetching: false,
            };
        }
        case GET_CATEGORY_PRODUCTS_ERROR: {
            return {
                ...state,
                isFetching: false,
            };
        }

        // Search.
        case SEARCH_PRODUCTS: {
            return {
                ...state,
                isFetching: true,
            };
        }
        case SEARCH_PRODUCTS_SUCCESS: {
            return {
                ...state,
                products: action.products,
                pagination: {
                    page: action.pagination.page,
                    pageSize: action.pagination.pageSize,
                    pageCount: action.pagination.pageCount,
                    hitsCount: action.pagination.hitsCount,
                },
                isFetching: false,
            };
        }
        case SEARCH_PRODUCTS_ERROR: {
            return {
                ...state,
                isFetching: false,
            };
        }

        // Filters.
        case APPLY_FILTER: {
            const { filter, value } = action;
            const appliedFilters = { ...state.appliedFilters };
            if (appliedFilters[filter] === undefined) {
                appliedFilters[filter] = [];
                appliedFilters[filter].push(value);
            } else if (appliedFilters[filter].indexOf(value) === -1) {
                appliedFilters[filter].push(value);
            }

            return {
                ...state,
                appliedFilters,
            };
        }
        case REMOVE_FILTER: {
            const { filter, value } = action;
            const { appliedFilters } = state;
            const newAppliedFilters = {};

            if (appliedFilters[filter] && appliedFilters[filter].length > 0) {
                const index = appliedFilters[filter].indexOf(value);
                if (index !== -1) {
                    appliedFilters[filter].splice(index, 1);
                }
            }

            // if the filter is empty, lets remove it
            // so we don't just return an empty array on the appliedFilter[key]
            const appliedFilterEntires = Object.entries(appliedFilters);
            appliedFilterEntires.forEach(([key, value]) => {
                if (value.length > 0) {
                    newAppliedFilters[key] = value;
                }
            });

            return {
                ...state,
                appliedFilters: newAppliedFilters,
            };
        }
        case CLEAR_FILTER: {
            return {
                ...state,
            };
        }
        case CLEAR_FILTERS: {
            const { appliedFilters } = state;
            const appliedFilterKeys = Object.keys(appliedFilters);

            appliedFilterKeys.forEach((key) => {
                if (key !== 'categoryId') {
                    appliedFilters[key] = [];
                }
            });

            return {
                ...state,
                appliedFilters,
            };
        }

        // Sort
        case APPLY_SORT: {
            return {
                ...state,
                appliedSort: action.sort,
            };
        }
        case CLEAR_SORT: {
            return {
                ...state,
                appliedSort: null,
            };
        }

        // VAT
        case SHOW_VAT: {
            return {
                ...state,
                showVat: action.showVat,
            };
        }

        case RESOLVE_PAGE_DEPENDENCIES_SUCCESS: {
            const newState = { ...state.algolia, ...action.data };
            return {
                ...state,
                algolia: newState,
                categoryTree: action.categoryTree || ['Resolve app reducer'],
            };
        }

        case SET_CATEGORY_TREE_SUCCESS: {
            return {
                ...state,
                categoryTree: action.categoryTree || ['Category tree reducer'],
            };
        }

        case GET_PRODUCTS_ERROR:
        case SET_ALL_PRODUCTS:
        case RESOLVE_PAGE_DEPENDENCIES:
        case RESOLVE_PAGE_DEPENDENCIES_ERROR:
        case SET_CATEGORY_TREE:
        case SET_CATEGORY_TREE_ERROR:
        case SET_ALL_PRODUCTS_ERROR:
        default:
            return state;
    }
};
