import store from 'core-web/state/store';
import { lockCheckout, setTotalPrice } from './actions';

export default {
    lockCheckout: () => {
        store.dispatch(lockCheckout(true));
    },

    unlockCheckout: () => {
        store.dispatch(lockCheckout(false));
    },

    setTotalPrice: (totalPrice) => {
        store.dispatch(setTotalPrice(totalPrice));
    },
};
