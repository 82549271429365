import tracking from 'core-web/components/Tracking';
import { put } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, partNo, basketItemId, quantity, pricelistId) => {
    const trackingData = tracking.getTrackingData();

    const data = {
        quantity,
        part_no: partNo,
        price_list_id: pricelistId,
        pricelist_id: pricelistId,
        application_id: getModel('application').applicationId,
        ...trackingData,
    };

    return put(`/frontend/basket/${basketId}/item/${basketItemId}`, data);
};
