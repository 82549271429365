import { put } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async ({ email, source, marketingConsent, emailConsent }) => {
    const data = {
        application_id: getModel('application').applicationId,
        signup_source: source,
        email,
        marketing_consent: marketingConsent,
        email_consent: emailConsent,
    };
    return put('/frontend/newsletter/signup', data);
};
