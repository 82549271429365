import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

interface Item {
    id: string;
    percentage?: string;
    price?: string;
}

interface Data {
    percentage?: string;
    price?: string;
    items?: Item[];
}

export default async (basketId: string, data: Data) => {
    const firebaseAuth = auth as unknown;

    if (!firebaseAuth || typeof firebaseAuth !== 'object') {
        throw new Error('Firebase auth is not available');
    }

    if (!('currentUser' in firebaseAuth)) {
        throw new Error('Firebase auth currentUser is not available');
    }

    const { currentUser } = firebaseAuth;

    if (!currentUser || typeof currentUser !== 'object') {
        throw new Error('Firebase auth currentUser is not available');
    }

    if (!('getIdToken' in currentUser) || typeof currentUser.getIdToken !== 'function') {
        throw new Error('Firebase auth currentUser getIdToken is not available');
    }

    const token = await currentUser.getIdToken();

    const response = await post(
        `/frontend/secret/${basketId}/update`,
        {
            application_id: getModel('application').applicationId,
            ...data,
        },
        { Authorization: `Bearer ${token}` },
    );

    return response.body();
};
