import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (basketId, customerId, companyId, returnDeliveryMethods = false, returnDeliveryPoints = false) => {
    if (!basketId) {
        return;
    }

    const token = await auth.currentUser.getIdToken();

    const parameters = {
        application_id: getModel('application').applicationId,
        customer_id: customerId || '',
        company_id: companyId || '',
        return_delivery_methods: returnDeliveryMethods || '',
        return_delivery_points: returnDeliveryPoints || '',
    };

    const response = await post(`/frontend/checkout/${basketId}/buyer`, parameters, {
        Authorization: `Bearer ${token}`,
    });

    return response.body();
};
