import React from 'react';
import loadable from '@loadable/component';
import Loading from 'theme/components/Loading';

const options = {
    fallback: <Loading />,
};

export default {
    accordion: loadable(() => import('./Accordion'), options),
    articles_spotlight: loadable(() => import('./Article'), options),
    brands: loadable(() => import('./Brands'), options),
    campaign: loadable(() => import('./Campaign'), options),
    // css: loadable(() => import('./CustomCss'), options),
    contact_panels: loadable(() => import('./ContactPanels'), options),
    double_campaign: loadable(() => import('./DoubleCampaign'), options),
    featured_products: loadable(() => import('./FeaturedProducts'), options),
    faq: loadable(() => import('./FAQ'), options),
    gallery: loadable(() => import('./Gallery'), options),
    guide: loadable(() => import('./GuideModule'), options),
    heading: loadable(() => import('./Heading'), options),
    hero: loadable(() => import('./Hero'), options),
    iframe: loadable(() => import('./Iframe'), options),
    newsletter: loadable(() => import('./Newsletter'), options),
    product_grid: loadable(() => import('./ProductGrid'), options),
    search_results: loadable(() => import('./SearchResults'), options),
    social: loadable(() => import('./Social'), options),
    text_blocks: loadable(() => import('./TextBlocksModule'), options),
    text_with_iframe: loadable(() => import('./TextWithIframe'), options),
    text_with_image: loadable(() => import('./TextWithImage'), options),
    text_for_custom_html: loadable(() => import('./TextForCustomHtml'), options),
    text: loadable(() => import('./TextField'), options),
    text_with_html: loadable(() => import('./TextWithHTML'), options),
    trustpilot: loadable(() => import('./Reviews'), options),
    usp: loadable(() => import('./Usp'), options),
};
