import GTM from '@grebban/gtm';

export default async (promotions) => {
    GTM.addDataLayerEvent('promotion_impression', {
        ecommerce: {
            promoView: {
                promotions: promotions.map((promo) => ({
                    id: promo.id,
                    name: promo.name,
                    creative: promo.creative,
                    position: promo.position,
                })),
            },
        },
    });
};
