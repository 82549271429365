import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (basketId, parameters = {}) => {
    let token;
    if (auth.currentUser) {
        token = await auth.currentUser.getIdToken();
    }

    const response = await post(
        `/frontend/basket/${basketId}/update`,
        {
            application_id: getModel('application').applicationId,
            ...parameters,
        },
        token ? { Authorization: `Bearer ${token}` } : {},
    );

    return response.body();
};
