import { get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (postCode: number, shippingProvider: string) => {
    const application = getModel('application');
    const { applicationId, locale } = application;

    return get(`/frontend/shipping/delivery-points?country=${locale.slice(-2).toUpperCase()}&postal_code=${postCode}`, {
        application_id: applicationId,
        shipping_provider: shippingProvider || 'post_nord',
    });
};
