import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { injectModels } from 'core-web/state';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import get from 'core-web/util/get';

const PaginationMeta = ({ page, application, hitsCount, seen, current = 0, total = 0 }) => {
    const { t } = useTranslation();

    const subDesc = (string) =>
        string
            .replace(/<\/{0,1}[^>]+>/g, '')
            .slice(0, 152)
            .concat('...');

    const getUrls = () => {
        let next;
        let prev;

        if (page.data) {
            const protocol = application.protocol ? application.protocol : '';
            const origin = application.origin ? application.origin : '';
            const baseUrl = protocol + origin;
            const { data } = page;
            const currentPage = current + 1;

            const url = baseUrl + data.permalink.toLowerCase();

            if (currentPage > 0 && currentPage < total) {
                next = `${url}?page=${currentPage + 1}`;
            }

            if (currentPage > 1) {
                prev = `${url}?page=${currentPage - 1}`;
            }
        }

        return {
            next,
            prev,
        };
    };

    const getDescription = () => {
        if (page.data) {
            const appName = process.env.REACT_APP_NAME || '';
            const { data } = page;
            const { title } = page.data;
            const currentPage = current + 1;
            let description = data.seo.description ? data.seo.description : data.seo.og_description;

            // If header description exist, use it as SEO description
            const cmsDescription = get(
                data,
                `settings.header_data.${
                    appName === 'maskinklippet' || appName === 'dogger' || appName === 'frishop'
                        ? 'top_description'
                        : 'description'
                }`,
            );
            if (cmsDescription && cmsDescription.length) {
                description = cmsDescription.replace(/(\r\n|\n|\r)/gm, '');
            }

            if (currentPage > 1) {
                let paginationDescription = `${t('page')} ${currentPage} ${t('for')} ${title}. ${t(
                    'shows_product',
                )} ${seen} ${t('out_of')} ${hitsCount}. ${description}`;
                paginationDescription = decodeHTMLEntities(paginationDescription);
                paginationDescription = subDesc(paginationDescription);

                return paginationDescription;
            }
        }
        return null;
    };

    const urls = getUrls();
    const getDesc = getDescription();

    return (
        <Helmet>
            {getDesc && <meta name="description" content={getDesc} />}
            {urls.prev !== undefined && <link rel="prev" href={urls.prev.replace(/\?page=1$/, '')} />}
            {urls.next !== undefined && <link rel="next" href={urls.next} />}
        </Helmet>
    );
};

PaginationMeta.propTypes = {
    application: PropTypes.object.isRequired,
    current: PropTypes.number,
    hitsCount: PropTypes.number,
    page: PropTypes.object.isRequired,
    seen: PropTypes.number,
    total: PropTypes.number,
};

PaginationMeta.defaultProps = {
    current: 0,
    hitsCount: null,
    seen: null,
    total: 0,
};

export default injectModels(['application', 'page'])(PaginationMeta);
