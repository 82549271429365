import { post } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';

export default async (cardNo, cvc) => {
    const { applicationId } = getModel('application');

    const data = {
        application_id: applicationId,
        card_no: cardNo,
        cvc,
        payment_method_id: process.env.REACT_APP_STORM_PAYMENT_METHOD_RETAIN24,
    };

    const response = await post(`/frontend/basket/gift-card/check`, data);
    return response.body();
};
