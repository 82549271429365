import { put } from 'core-web/libs/grebbcommerce-api/util/authClient';
import { getModel } from 'core-web/state';
import { auth } from 'core-web/util/firebase';

export default async (basketId, data) => {
    const formData = {
        quantity: data.quantity,
        line_no: data.lineNo,
        price_list_id: data.priceListId,
        part_no: data.partNo,
        application_id: getModel('application').applicationId,
    };
    if (data.price) {
        formData.price = data.price;
    }
    if (data.percentage) {
        formData.percentage = data.percentage;
    }
    if (data.price === 0) {
        formData.price = data.price;
    }
    if (data.percentage === 0) {
        formData.percentage = data.percentage;
    }

    const token = await auth.currentUser.getIdToken();

    const response = await put(`/frontend/secret/${basketId}/item/${data.basketItemId}`, formData, {
        Authorization: `Bearer ${token}`,
    });

    return response.body();
};
