const objectIsEmpty = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }

    // If IE - Object.entries does not exist
    if (!Object.entries) {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i);

        while (i > 0) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
            i -= 1;
        }
        if (resArray.length) {
            return false;
        }
    }

    return Object.entries(obj).length === 0;
};

export default objectIsEmpty;
