// Trims whitespace of all strings in an object
export const trimObjectValues = (obj: Record<string, unknown>) => {
    const objEntries = Object.entries(obj);

    objEntries.forEach(([key, value]) => {
        if (typeof value === 'string') {
            obj[key as keyof typeof obj] = value.trim();
        } else if (!!value && typeof value === 'object') {
            trimObjectValues(value as Record<string, unknown>);
        }
    });

    return obj;
};
