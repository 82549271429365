import getCustomerSalesAuth from 'core-web/util/getCustomerSalesAuth';
import { getState } from 'core-web/state';
import { STORM_SALES_REP } from 'theme/config/constants';

export const addSalesRepInfo = (basket, customerCode) => {
    const { basketId } = basket;

    const salesRepData = {
        infos: [
            {
                code: STORM_SALES_REP,
                value: customerCode,
            },
        ],
    };

    basket.updateBasket(basketId, salesRepData);
};
export const shouldAddSalesRepInfo = () => {
    const basket = getState('basket');
    const customer = getState('customer');

    const userIsAdmin = getCustomerSalesAuth(customer.roles);
    const salesRepIsSet = basket.info.find((i) => i.code === STORM_SALES_REP).value !== '';
    if (!userIsAdmin || salesRepIsSet) {
        return;
    }

    addSalesRepInfo(basket, customer.code);
};
export const clearSalesRepInfo = () => {
    const basket = getState('basket');
    const { basketId } = basket;
    if (!basketId) {
        return;
    }
    const salesRepIsSet = !!basket.info.find((i) => i.code === STORM_SALES_REP)?.value;
    const salesRepInfo = {
        infos: [{ code: STORM_SALES_REP, value: '' }],
    };
    if (salesRepIsSet) {
        basket.updateBasket(basketId, salesRepInfo);
    }
};
