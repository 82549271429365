import { keyframes } from '@emotion/core';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const SpinWrapper = styled('div')`
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const Dash = keyframes`
  0% { stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 10;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`;

const Rotator = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`;

const Spinner = styled('svg')`
    animation: ${Rotator} 1.2s linear infinite;
`;

const Circle = styled('circle')`
    stroke-dasharray: 187;
    stroke-dashoffset: 60;
    transform-origin: center;
    animation: ${Dash} 1.2s ease-in-out infinite;
`;

const LoadingDots = ({ bg = 'darkGrey', width = '40px', height = '40px', ...props }) => (
    <SpinWrapper {...props}>
        <Spinner width={width} height={height} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <Circle
                stroke={colors[bg] ? colors[bg] : bg}
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                cx="33"
                cy="33"
                r="30"
            />
        </Spinner>
    </SpinWrapper>
);

LoadingDots.propTypes = {
    bg: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default LoadingDots;
