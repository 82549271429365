export default (arr) => {
    let r = Promise.resolve();
    return Promise.all(
        arr.reduce((p, c) => {
            r = r.then(() => (typeof c === 'function' ? c() : c));
            p.push(r);
            return p;
        }, []),
    );
};
