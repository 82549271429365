export const round = (amount) => parseFloat(amount, 10);

export const getImpression = (product, position = null) => {
    if (!product) {
        return;
    }

    const {
        categories,
        categoryPrimary,
        list,
        manufacturer_name: manufacturerName,
        manufacturer,
        name,
        objectID,
        parentProductName,
        pricing,
        productId,
    } = product;

    const dataProduct = {
        name: name || '',
        nameFormatted: parentProductName,
        id: objectID || '',
        price: pricing?.pricelists?.web && round(pricing.pricelists.web.incVat),
        brand: manufacturer?.name || manufacturerName || '',
        productId: `${productId || ''}`,
    };

    if (categoryPrimary && Array.isArray(categories) && categories.length > 0) {
        dataProduct.category = (categories.find((c) => c.id === categoryPrimary) || {}).name || '';
    }

    // Variant
    // let variant = [];
    // if(variant.length > 0) {
    //     dataProduct.variant = variant.map(data => data).join('|')
    // }

    if (list) {
        dataProduct.list = list;
    }

    if (position !== null) {
        dataProduct.position = position + 1;
    }
    return dataProduct;
};

export const getImpressions = (items) => {
    const impressions = [];
    for (let i = 0; i < items.length; i++) {
        impressions.push(getImpression(items[i], i));
    }
    return impressions.filter((item) => item);
};
