import getQueryParams from 'core-web/util/getQueryParams';
import {
    // Search.
    SEARCH,
    SEARCH_SUCCESS,
    SEARCH_ERROR,
    SEARCH_CLEAR,
    SEARCH_CLEAR_SUCCESS,
    SEARCH_CLEAR_ERROR,
    SEARCH_DEFAULT_QUERY,
    SEARCH_DEFAULT_QUERY_SUCCESS,
    SEARCH_DEFAULT_QUERY_ERROR,
} from './constants';

export const search = (query, history, location) => (dispatch) => {
    dispatch({ type: SEARCH });
    try {
        const params = getQueryParams(location);
        params.q = encodeURI(query);

        if (query) {
            try {
                params.q = decodeURI(query);
            } catch (e) {
                console.error(e, 'Failed decode in dispatch');
            }
        } else {
            delete params.q;
        }

        history.replace(
            `?${Object.entries(params)
                .map((q) => q.join('='))
                .join('&')}`,
        );

        dispatch({
            type: SEARCH_SUCCESS,
            query,
        });
    } catch (e) {
        dispatch({ type: SEARCH_ERROR });
        throw e;
    }
};

export const clear = (history, location) => (dispatch) => {
    dispatch({ type: SEARCH_CLEAR });
    try {
        const params = getQueryParams(location);
        delete params.search;
        history.push(
            `?${Object.entries(params)
                .map((q) => q.join('='))
                .join('&')}`,
        );

        dispatch({ type: SEARCH_CLEAR_SUCCESS });
    } catch (e) {
        dispatch({ type: SEARCH_CLEAR_ERROR });
        throw e;
    }
};

export const queryDefault = (location) => (dispatch) => {
    dispatch({ type: SEARCH_DEFAULT_QUERY });
    try {
        const params = getQueryParams(location);
        let query = params.q || '';

        if (query) {
            query = encodeURI(query);
            try {
                query = decodeURI(query);
            } catch (e) {
                console.error(e, 'Failed decode in dispatch');
            }
        } else {
            delete params.q;
        }

        dispatch({
            type: SEARCH_DEFAULT_QUERY_SUCCESS,
            query,
        });
    } catch (e) {
        dispatch({ type: SEARCH_DEFAULT_QUERY_ERROR });
        throw e;
    }
};
