import { buildQueryString, post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (checkoutId) => {
    const data = {
        payment_parameters: [
            {
                Name: 'checkoutId',
                Value: checkoutId,
            },
            {
                Name: 'PaymentService',
                Value: 'KlarnaCheckoutV3',
            },
        ],
    };
    const builtParameters = buildQueryString({ application_id: getModel('application').applicationId });
    const parameterString = builtParameters ? `?${builtParameters}` : '';
    return post(`/frontend/payment/callback${parameterString}`, data);
};
