import getQueryParams from 'core-web/util/getQueryParams';
import { FILTER_SEARCH, FILTER_SEARCH_ERROR, FILTER_SEARCH_SUCCESS } from './constants';

export const search = (query, history, location) => (dispatch) => {
    dispatch({ type: FILTER_SEARCH });

    try {
        const params = getQueryParams(location);

        params.q = encodeURI(query);
        delete params.page;

        if (query) {
            try {
                params.q = decodeURI(query);
            } catch (e) {
                // console.error(e, 'Failed decode in dispatch');
            }
        } else {
            delete params.q;
        }

        history.replace(
            `?${Object.entries(params)
                .map((q) => q.join('='))
                .join('&')}`,
        );

        dispatch({
            type: FILTER_SEARCH_SUCCESS,
            query,
        });
    } catch (e) {
        dispatch({ type: FILTER_SEARCH_ERROR });
        throw e;
    }
};

export const clear = (history, location) => (dispatch) => {
    dispatch({ type: FILTER_SEARCH });

    try {
        const params = getQueryParams(location);

        if (params.q) {
            delete params.q;
        }

        history.replace(
            `?${Object.entries(params)
                .map((q) => q.join('='))
                .join('&')}`,
        );

        dispatch({
            type: FILTER_SEARCH_SUCCESS,
            query: '',
        });
    } catch (e) {
        dispatch({ type: FILTER_SEARCH_ERROR });
        throw e;
    }
};
