// Get Basket
export const GET_BASKET = 'GET_BASKET';
export const GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS';
export const GET_BASKET_ERROR = 'GET_BASKET_ERROR';

// Get Abandoned Basket
export const GET_ABANDONED_BASKET = 'GET_ABANDONED_BASKET';
export const GET_ABANDONED_BASKET_SUCCESS = 'GET_ABANDONED_BASKET_SUCCESS';
export const GET_ABANDONED_BASKET_ERROR = 'GET_ABANDONED_BASKET_ERROR';

// Set Basket
export const SET_BASKET = 'SET_BASKET';
export const SET_BASKET_SUCCESS = 'SET_BASKET_SUCCESS';
export const SET_BASKET_ERROR = 'SET_BASKET_ERROR';

// Update Basket
export const UPDATE_BASKET = 'UPDATE_BASKET';
export const UPDATE_BASKET_SUCCESS = 'UPDATE_BASKET_SUCCESS';
export const UPDATE_BASKET_ERROR = 'UPDATE_BASKET_ERROR';

// Update Basket Secret
export const UPDATE_SECRET_BASKET = 'UPDATE_SECRET_BASKET';
export const UPDATE_BASKET_SECRET_SUCCESS = 'UPDATE_BASKET_SECRET_SUCCESS';
export const UPDATE_BASKET_SECRET_ERROR = 'UPDATE_BASKET_SECRET_ERROR';

// Update Basket Buyer
export const UPDATE_BASKET_BUYER = 'UPDATE_BASKET_BUYER';
export const UPDATE_BASKET_BUYER_SUCCESS = 'UPDATE_BASKET_BUYER_SUCCESS';
export const UPDATE_BASKET_BUYER_ERROR = 'UPDATE_BASKET_BUYER_ERROR';

// Reset Basket Buyer
export const RESET_BASKET_BUYER = 'RESET_BASKET_BUYER';
export const RESET_BASKET_BUYER_SUCCESS = 'RESET_BASKET_BUYER_SUCCESS';
export const RESET_BASKET_BUYER_ERROR = 'RESET_BASKET_BUYER_ERROR';

// Update Secret Basket Buyer
export const UPDATE_SECRET_BASKET_BUYER = 'UPDATE_SECRET_BASKET_BUYER';
export const UPDATE_SECRET_BASKET_BUYER_SUCCESS = 'UPDATE_SECRET_BASKET_BUYER_SUCCESS';
export const UPDATE_SECRET_BASKET_BUYER_ERROR = 'UPDATE_SECRET_BASKET_BUYER_ERROR';

// Create Basket
export const CREATE_BASKET = 'CREATE_BASKET';
export const CREATE_BASKET_SUCCESS = 'CREATE_BASKET_SUCCESS';
export const CREATE_BASKET_ERROR = 'CREATE_BASKET_ERROR';

// Add to Basket
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const ADD_TO_BASKET_SUCCESS = 'ADD_TO_BASKET_SUCCESS';
export const ADD_TO_BASKET_ERROR = 'ADD_TO_BASKET_ERROR';

// Add items to Basket
export const ADD_BASKET_ITEMS = 'ADD_BASKET_ITEMS';
export const ADD_BASKET_ITEMS_SUCCESS = 'ADD_BASKET_ITEMS_SUCCESS';
export const ADD_BASKET_ITEMS_ERROR = 'ADD_BASKET_ITEMS_ERROR';

// Add package to Basket
export const ADD_PACKAGE_TO_BASKET = 'ADD_PACKAGE_TO_BASKET';
export const ADD_PACKAGE_TO_BASKET_SUCCESS = 'ADD_PACKAGE_TO_BASKET_SUCCESS';
export const ADD_PACKAGE_TO_BASKET_ERROR = 'ADD_PACKAGE_TO_BASKET_ERROR';

// Remove Basket
export const REMOVE_BASKET = 'REMOVE_BASKET';
export const REMOVE_BASKET_SUCCESS = 'REMOVE_BASKET_SUCCESS';
export const REMOVE_BASKET_ERROR = 'REMOVE_BASKET_ERROR';

// Update basket item
export const UPDATE_BASKET_ITEM = 'UPDATE_BASKET_ITEM';
export const UPDATE_BASKET_ITEM_SUCCESS = 'UPDATE_BASKET_ITEM_SUCCESS';
export const UPDATE_BASKET_ITEM_ERROR = 'UPDATE_BASKET_ITEM_ERROR';

// Update basket items
export const UPDATE_BASKET_ITEMS = 'UPDATE_BASKET_ITEMS';
export const UPDATE_BASKET_ITEMS_SUCCESS = 'UPDATE_BASKET_ITEMS_SUCCESS';
export const UPDATE_BASKET_ITEMS_ERROR = 'UPDATE_BASKET_ITEMS_ERROR';

// Update item price
export const UPDATE_ITEM_PRICE = 'UPDATE_ITEM_PRICE';
export const UPDATE_ITEM_PRICE_SUCCESS = 'UPDATE_ITEM_PRICE_SUCCESS';
export const UPDATE_ITEM_PRICE_ERROR = 'UPDATE_ITEM_PRICE_ERROR';

// Update package in Basket
export const UPDATE_BASKET_PACKAGE_ITEM = 'UPDATE_BASKET_PACKAGE_ITEM';
export const UPDATE_BASKET_PACKAGE_ITEM_SUCCESS = 'UPDATE_BASKET_PACKAGE_ITEM_SUCCESS';
export const UPDATE_BASKET_PACKAGE_ITEM_ERROR = 'UPDATE_BASKET_PACKAGE_ITEM_ERROR';

// Empty Basket
export const EMPTY_BASKET = 'EMPTY_BASKET ';
export const EMPTY_BASKET_SUCCESS = 'EMPTY_BASKET_SUCCESS';
export const EMPTY_BASKET_ERROR = 'EMPTY_BASKET_ERROR';

// Show/Hide Basket
export const OPEN_BASKET = 'OPEN_BASKET';
export const CLOSE_BASKET = 'CLOSE_BASKET';
export const TOGGLE_BASKET = 'TOGGLE_BASKET';

// Add Gift Card
export const ADD_GIFT_CARD = 'ADD_GIFT_CARD';
export const ADD_GIFT_CARD_SUCCESS = 'ADD_GIFT_CARD_SUCCESS';
export const ADD_GIFT_CARD_ERROR = 'ADD_GIFT_CARD_ERROR';

// Remove Gift Card
export const ROLLBACK_GIFT_CARD = 'ROLLBACK_GIFT_CARD';
export const ROLLBACK_GIFT_CARD_SUCCESS = 'ROLLBACK_GIFT_CARD_SUCCESS';
export const ROLLBACK_GIFT_CARD_ERROR = 'ROLLBACK_GIFT_CARD_ERROR';

// Add voucher
export const ADD_VOUCHER = 'ADD_VOUCHER';
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_ERROR = 'ADD_VOUCHER_ERROR';

// Add voucher
export const REMOVE_VOUCHER = 'REMOVE_VOUCHER';
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS';
export const REMOVE_VOUCHER_ERROR = 'REMOVE_VOUCHER_ERROR';

// Payment method
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR';

// Service points
export const GET_SERVICE_POINTS = 'GET_SERVICE_POINTS';
export const GET_SERVICE_POINTS_SUCCESS = 'GET_SERVICE_POINTS_SUCCESS';
export const GET_SERVICE_POINTS_ERROR = 'GET_SERVICE_POINTS_ERROR';

// Service point
export const UPDATE_SERVICE_POINT = 'UPDATE_SERVICE_POINT';
export const UPDATE_SERVICE_POINT_SUCCESS = 'UPDATE_SERVICE_POINT_SUCCESS';
export const UPDATE_SERVICE_POINT_ERROR = 'UPDATE_SERVICE_POINT_ERROR';

// Delivery method
export const UPDATE_DELIVERY_METHOD = 'UPDATE_DELIVERY_METHOD';
export const UPDATE_DELIVERY_METHOD_SUCCESS = 'UPDATE_DELIVERY_METHOD_SUCCESS';
export const UPDATE_DELIVERY_METHOD_ERROR = 'UPDATE_DELIVERY_METHOD_ERROR';

// Reset delivery method
export const RESET_DELIVERY_METHOD = 'RESET_DELIVERY_METHOD';
export const RESET_DELIVERY_METHOD_SUCCESS = 'RESET_DELIVERY_METHOD_SUCCESS';
export const RESET_DELIVERY_METHOD_ERROR = 'RESET_DELIVERY_METHOD_ERROR';

// Get checkout
export const GET_CHECKOUT = 'GET_CHECKOUT';
export const GET_CHECKOUT_SUCCESS = 'GET_CHECKOUT_SUCCESS';
export const GET_CHECKOUT_ERROR = 'GET_CHECKOUT_ERROR';

export const GET_BASKET_PAYMENT = 'GET_BASKET_PAYMENT';
export const GET_BASKET_PAYMENT_SUCCESS = 'GET_BASKET_PAYMENT_SUCCESS';
export const GET_BASKET_PAYMENT_ERROR = 'GET_BASKET_PAYMENT_ERROR';

export const ADD_BASKET_PAYMENT_FIELDS = 'ADD_BASKET_PAYMENT_FIELDS';
export const ADD_BASKET_PAYMENT_FIELDS_SUCCESS = 'ADD_BASKET_PAYMENT_FIELDS_SUCCESS';
export const ADD_BASKET_PAYMENT_FIELDS_ERROR = 'ADD_BASKET_PAYMENT_FIELDS_ERROR';

export const UPDATE_ITEM_DISCOUNTS = 'UPDATE_ITEM_DISCOUNTS';
export const UPDATE_ITEM_DISCOUNTS_SUCCESS = 'UPDATE_ITEM_DISCOUNTS_SUCCESS';
export const UPDATE_ITEM_DISCOUNTS_ERROR = 'UPDATE_ITEM_DISCOUNTS_ERROR';
