import {
    // Overlay.
    SHOW_OVERLAY,
    HIDE_OVERLAYS,
} from './constants';

export const show =
    (current, data = {}, wrapper = null) =>
    (dispatch) => {
        dispatch({
            type: SHOW_OVERLAY,
            current,
            data,
            wrapper,
        });
    };

export const hide = () => (dispatch) => {
    dispatch({ type: HIDE_OVERLAYS });
};
