import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
// prettier-ignore
import { loadableReady } from '@loadable/component';
import App from 'core-web/components/App';
import { hydrate } from 'emotion';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { unregister } from 'serviceWorker';
// @todo: the line below could be used when creating the store, google tag manager and stuff, check royal web for inspiration
// import providers from 'middlewares/providers';
import store from 'core-web/state/store';
// Setup Sentry for client in production
import { EMOTION_IDS_KEY, INITIAL_PROMISES_CONTEXT, inProduction } from 'core-web/constants';
import { APPLICATION_LOCALE_KEY } from 'core-web/constants/translation';
import { PromisesProvider } from 'core-web/contexts/promises-context';
import { sentry } from 'core-web/util/sentry';
import { initTranslation } from 'core-web/util/translation';

sentry();

const locale = window[APPLICATION_LOCALE_KEY] || process.env.REACT_APP_DEFAULT_LOCALE;
const localePromise = import(
    /* webpackChunkName: "[request]" */
    `locales/${locale}.json`
);

if (window[EMOTION_IDS_KEY]) {
    hydrate(window[EMOTION_IDS_KEY]);
}
delete window[EMOTION_IDS_KEY];

localePromise.then((translation) => {
    initTranslation({
        lng: locale,
        resources: {
            [locale]: {
                translation,
            },
        },
    });

    let promisesContext = { promises: {}, responses: {} };

    // take default from data we saved in SSR
    if (typeof window !== 'undefined' && window[INITIAL_PROMISES_CONTEXT]) {
        promisesContext = window[INITIAL_PROMISES_CONTEXT];
        delete window[INITIAL_PROMISES_CONTEXT];
    }

    const wrappedTree = (
        <PromisesProvider value={promisesContext}>
            <CookiesProvider>
                <Provider store={store}>
                    <BrowserRouter>
                        <HelmetProvider>
                            <App />
                        </HelmetProvider>
                    </BrowserRouter>
                </Provider>
            </CookiesProvider>
        </PromisesProvider>
    );

    const renderMethod = inProduction ? ReactDOM.hydrate : ReactDOM.render;

    loadableReady(() => renderMethod(wrappedTree, document.getElementById('root')));

    unregister();
    // register(); if you want the service worker
});
