import { checkStormMediaUrl } from 'core-web/util/checkStormMediaUrl';
import { breakpoints as themeBreakpoints } from 'theme/config/breakpoints';

interface StormParams {
    [key: string]: string | number | undefined;
    anchor?:
        | 'topleft'
        | 'topcenter'
        | 'topright'
        | 'middleleft'
        | 'middlecenter'
        | 'middleright'
        | 'bottomleft'
        | 'bottomcenter'
        | 'bottomright';
    bgcolor?: string;
    f?: 'gif' | 'jpg' | 'png' | 'webp';
    h?: number;
    m?: 'max' | 'pad' | 'crop' | 'carve' | 'stretch';
    q?: number;
    scale?: 'downscaleonly' | 'upscaleonly' | 'both' | 'upscalecanvas' | 'down';
    w?: number;
}

interface ImgixParams {
    [key: string]: string | number | undefined;
    auto?: 'compress' | 'enhance' | 'format' | 'redeye';
    bg?: string;
    fit?: 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'fillmax' | 'max' | 'min' | 'scale';
    fm?: 'gif' | 'jpg' | 'png' | 'webp';
    h?: number;
    q?: number;
    w?: number;
}

const mediaCondition = (minWidth: string | number, size: string) => `(min-width: ${minWidth}) ${size}`;

/**
 *
 * @param {string[]} widths - Array of widths specified in unit
 * @param {string[]} [breakpoints] = Array of breakpoints. Defaults to theme breakpoints.
 * @returns {string} - String with media queries based on widths and breakpoints.
 *
 * @example
 *      generateSizes(['50vw', null, '25vw'], ['375px', '768px', '1024px']);
 *      '(min-width: 1024px) 25vw, 50vw'
 *
 */
const generateSizes = (widths: string[], breakpoints: string[] = themeBreakpoints) => {
    const result = [];

    if (!widths || !widths.length) {
        return null;
    }

    if (!Array.isArray(widths)) {
        return widths;
    }

    for (let i = 0; i < widths.length; i++) {
        const width = widths[i];

        if (width !== null && width !== undefined) {
            result.push(!result.length ? width : mediaCondition(breakpoints[i], width));
        }
    }

    return result.reverse().join(', ');
};

/**
 *
 * @param {string} url - URL to concat with query strings.
 * @param {Object} params - Object with param options.
 * @param {string[]} keyMap - Object with key/value with valid params and transforms.
 * @returns {string} - URL with query strings.
 *
 * @example
 *      generateSrc('img.jpg', { width: 200 }, { width: 'w' });
 *      'img.jpg?w=200'
 *
 */
const generateSrc = (url: string, params: StormParams | ImgixParams) => {
    let src = url;

    if (params) {
        let prefix: string | null = null;

        const paramEntries = Object.entries(params);

        paramEntries.forEach(([key, value]) => {
            value = (value || '').toString();

            if (value) {
                // Since we dont have an index in this loop prefix starts with null and displays an ?. For remaning prefix is set to &
                // If value starts with # it's a hex color, remove # since it's not valid in queryparams.
                src = `${src}${(prefix = prefix ? '&' : '?')}${key}=${
                    value.indexOf('#') === 0 ? value.slice(1) : value
                }`;
            }
        });
    }

    return src;
};

/**
 *
 * @param {string} url - URL to append width query string and height if supplied.
 * @param {number[]} widths - Range of different image widths
 * @param {number[]} [heights] - Range of different image heights, cannot be greater than widths.
 *
 * @example
 *      generateSrcSet('img.jpg', [100, 200], [100]);
 *      'img.jpg?w=100&h=100 100w, img.jpg?w=200 200w'
 *
 */
const generateSrcSet = (url: string, widths: number[], heights: number[] = []) => {
    const prefix = url.indexOf('?') > -1 ? '&' : '?';

    let src = url.endsWith('?') ? url.slice(0, -1) : url;

    // remove w and h query param from src if exsist
    src = src.replace(new RegExp(`(\\?|\\&){0,1}(?:${[heights].length ? 'w|h' : 'w'})\\=\\d+\\&{0,1}`, 'g'), '$1');
    if (src.endsWith('&')) {
        src = src.slice(0, -1);
    }

    return widths
        .map((width, index) => {
            const widthParam = `w=${width}`;
            const height = heights[index];
            const heightParam = height ? `&h=${height}` : '';

            return `${src}${prefix}${widthParam}${heightParam} ${width}w`;
        })
        .join(', ');
};

/**
 *
 * @param {string} url - URL to an imgix image
 * @param {Object} params - Format options
 *
 * @example
 *      imgixSrc('img.jpg', { format: 'jpg', width: 200 });
 *      'img.jpg?fm=jpgw=200'
 *
 */
const imgixSrc = (url: string, params: ImgixParams) => generateSrc(url, params);

/**
 *
 * @param {string} url - URL to an storm image
 * @param {Object} params - Format options
 *
 * @example
 *      stormSrc('img.jpg', { mode: 'pad', width: 200 });
 *      'img.jpg?mode=padw=200'
 *
 */
const stormSrc = (url: string, params: StormParams) => generateSrc(checkStormMediaUrl(url), params);

export { generateSizes, generateSrc, generateSrcSet, imgixSrc, stormSrc };
