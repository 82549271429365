import store from 'core-web/state/store';
import { getProductPrices, initCustomer, logout, setCustomer, updateCustomer } from './actions';

export { default as CustomerReducers } from './reducers';
export default {
    initCustomer: (customer) => store.dispatch(initCustomer(customer)),
    setCustomer: (customer) => store.dispatch(setCustomer(customer)),
    getProductPrices: (products) => store.dispatch(getProductPrices(products)),
    updateCustomer: (companyId, customerId) => store.dispatch(updateCustomer(companyId, customerId)),
    logout: () => store.dispatch(logout()),
};
