import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const CHECKBOX_SIZES = {
    small: {
        lineHeight: '14px',
        textMargin: '16px',
        before: {
            size: '8px',
        },
        after: {
            size: '4px',
            margin: '2px',
        },
    },
    medium: {
        lineHeight: '16px',
        textMargin: '24px',
        before: {
            size: '16px',
        },
        after: {
            size: '8px',
            margin: '4px',
        },
    },
    large: {
        lineHeight: '24px',
        textMargin: '32px',
        before: {
            size: '24px',
        },
        after: {
            size: '16px',
            margin: '4px',
        },
    },
};

const Input = styled('input')`
    -webkit-appearance: checkbox;
    width: auto;
    display: none;

    &:checked + label::before {
        border-color: ${colors.green};
    }

    &:checked + label::after {
        content: '';
    }
`;

const Label = styled('label', { shouldForwardProp: (prop) => prop !== 'size' })`
    display: block;
    position: relative;
    left: 0;
    cursor: pointer;
    user-select: none;
    font: 400 14px 'Inter';
    line-height: ${({ size: { lineHeight } }) => lineHeight};
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        border-radius: 2px;
        border: 1px solid ${colors.grey};
        background: ${colors.white};
        top: 0;
        padding: 1px;
        transition: border 200ms ease;

        ${({ size: { before } }) => `
            width: ${before.size};
            height: ${before.size};
        `}
    }

    &::after {
        position: absolute;
        border-radius: 2px;
        background: ${colors.green};
        top: 0;

        ${({ size: { after } }) => `
            width: ${after.size};
            height: ${after.size};
            margin: ${after.margin};
        `}
    }

    &.text-before {
        padding-right: ${({ size: { textMargin } }) => textMargin};

        &::before,
        &::after {
            left: auto;
            right: 0;
        }
    }

    &.text-after {
        padding-left: ${({ size: { textMargin } }) => textMargin};

        &::before,
        &::after {
            left: 0;
        }
    }

    &:hover::before {
        border-color: ${colors.green};
    }
`;

const Checkbox = ({ id, name, value, children, isChecked, alignText, size, onChange }) => {
    const [checked, setChecked] = useState(isChecked);

    useEffect(() => {
        if (isChecked !== checked) {
            setChecked(isChecked);
        }
    }, [isChecked]);

    const onCheckboxChange = (e) => {
        setChecked(e.target.checked);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <Input
                hidden
                type="checkbox"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onCheckboxChange}
            />
            <Label htmlFor={id} size={CHECKBOX_SIZES[size]} className={`text-${alignText}`}>
                {children}
            </Label>
        </>
    );
};

Checkbox.propTypes = {
    alignText: PropTypes.oneOf(['after', 'before']),
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    value: PropTypes.string,
};

Checkbox.defaultProps = {
    alignText: 'before',
    children: null,
    isChecked: false,
    name: null,
    onChange: null,
    size: 'medium',
    value: undefined,
};

export default Checkbox;
