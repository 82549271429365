import tracking from 'core-web/components/Tracking';
import { buildQueryString, get } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId) => {
    const trackingData = tracking.getTrackingData();

    const data = {
        ...trackingData,
    };

    const parameters = buildQueryString({ application_id: getModel('application').applicationId });
    const parameterString = parameters ? `?${parameters}` : '';
    return get(`/frontend/checkout/${basketId}/confirmation${parameterString}`, data);
};
