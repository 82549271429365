import Events from 'core-web/libs/Events';
import { BasketEvents } from 'core-web/libs/Events/constants';
import { DeleteBasketItem, UpdateBasketItems, UpdateBasketPackage } from 'core-web/libs/GrebbCommerceAPI/Basket';
import { getFlag } from 'core-web/util/getFlag';
import getProductStockStatus from 'core-web/util/getProductStockStatus';
import resolveSequentially from 'core-web/util/resolveSequentially';
import { PRODUCT_BACKORDER, PRODUCT_PREORDER, STORM_PACKAGE_TYPES } from 'theme/config/constants';

export const itemIsPackage = (item) => STORM_PACKAGE_TYPES.indexOf(item.type) !== -1;

const promotionProductExists = (items, partNo) => items.find((i) => i.id === 0 && i.part_no === partNo);

export const checkOnhandStatus = (basketItems) =>
    (
        basketItems?.filter(
            (i) =>
                (i.on_hand_status.is_ok === false && !getFlag([PRODUCT_BACKORDER, PRODUCT_PREORDER], i.format.flags)) ||
                itemIsPackage(i) ||
                promotionProductExists(basketItems, i.part_no),
        ) || []
    ).reduce((items, item) => {
        const promotionExists = promotionProductExists(basketItems, item.part_no);

        const isPackage = itemIsPackage(item);
        const subProductsIsOk = isPackage && item?.sub_products?.every((i) => i.on_hand_status.is_ok === true);

        let lowestQuantityAvailable = null;
        if (subProductsIsOk) {
            return items;
        }
        if (isPackage) {
            const products = item?.sub_products.map((x) => x);
            const availablePackageQuantities = products
                .filter((i) => i.on_hand_status.on_hand !== null)
                .map((subItem) => {
                    const packageStructureItem = item.included_products.find(
                        (includedProduct) =>
                            includedProduct.variants?.some((variant) => variant.part_no === subItem.part_no) ||
                            includedProduct.part_no === subItem.part_no,
                    );

                    return Math.floor(subItem.on_hand_status.on_hand / packageStructureItem.quantity);
                });

            lowestQuantityAvailable = Math.min(...availablePackageQuantities);
        } else if (item.id === 0) {
            return items;
        }

        const {
            quantity,
            on_hand_status: { on_hand: onHandStatus },
            on_hand: onHand,
            on_hand_store: onHandStore,
        } = item;

        const { stock } = getProductStockStatus([onHand || {}, onHandStore || {}]);
        let newOnHandStock = onHandStatus;

        if (promotionExists?.part_no === item.part_no) {
            if (!onHandStatus && stock === item.quantity) {
                newOnHandStock = stock - promotionExists.quantity;
            } else {
                newOnHandStock = onHandStatus - promotionExists.quantity;
            }
        }

        const updatedStock = isPackage ? lowestQuantityAvailable : newOnHandStock;

        const quantityChange = updatedStock > 0 ? quantity - updatedStock : 0;

        const productUpdateData = {
            line_no: item.line_no,
            part_no: item.part_no,
            quantity: updatedStock,
        };

        const basketItem = { item, productUpdateData, quantityChange, updatedStock };

        return [...items, basketItem];
    }, []);

export const sendOnhandQuantityEvent = (partNo) => {
    Events.trigger(BasketEvents.PRODUCTS_ONHAND_CHANGE, {
        event: 'log',
        type: 'Onhand basket quantity changed',
        message: partNo,
    });
};

// prepare promises/update-data to cleanup checkout component
export const getOnhandUpdateData = (basket, onHandChangeData) => {
    const productsToUpdate = [];
    const packagesToUpdate = [];
    const productsToRemove = [];

    for (let i = 0; i < onHandChangeData.length; i++) {
        const currentProduct = onHandChangeData[i];
        const { item } = currentProduct;
        const { productUpdateData } = currentProduct;

        if (currentProduct.updatedStock > 0 && currentProduct.updatedStock < item.quantity) {
            if (itemIsPackage(item)) {
                packagesToUpdate.push(basket.updateBasketPackageItem(item, currentProduct.updatedStock, 'remove'));
            } else {
                productsToUpdate.push(productUpdateData);
            }
            sendOnhandQuantityEvent(item.part_no);
        } else if (currentProduct.updatedStock <= 0) {
            productsToRemove.push(item);
        }
    }

    return { packagesToUpdate, productsToUpdate, productsToRemove };
};

export const getOnhandUpdateDataRaw = async (basketId, onHandChangeData) => {
    const productsToUpdate = [];
    const promises = [];
    const productsToRemove = [];

    for (let i = 0; i < onHandChangeData.length; i++) {
        const currentProduct = onHandChangeData[i];
        const { item } = currentProduct;
        const { productUpdateData } = currentProduct;

        if (currentProduct.updatedStock > 0 && currentProduct.updatedStock < item.quantity) {
            if (itemIsPackage(item)) {
                promises.push(UpdateBasketPackage.bind(null, basketId, item.line_no, currentProduct.updatedStock));
            } else {
                productsToUpdate.push(productUpdateData);
            }
        } else if (currentProduct.updatedStock <= 0) {
            productsToRemove.push(item);
        }
    }

    if (productsToUpdate.length) {
        promises.push(UpdateBasketItems.bind(null, basketId, productsToUpdate));
    }

    for (let index = 0; index < productsToRemove.length; index++) {
        const item = productsToRemove[index];
        promises.push(DeleteBasketItem.bind(null, basketId, item.line_no));
    }

    return promises.length ? resolveSequentially(promises) : null;
};

export const handleRemoveBasketItems = async (basket, productsToRemove) => {
    await Promise.all(
        productsToRemove.map(async (item) => {
            await basket.modifyBasketItemQuantity(item, 0, true);
        }),
    );
};
