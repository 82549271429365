import GTM from '@grebban/gtm';

export default (promotion) => {
    GTM.addDataLayerEvent('promotion_click', {
        ecommerce: {
            promoClick: {
                promotions: [
                    {
                        id: promotion.id,
                        name: promotion.name,
                        creative: promotion.creative,
                        position: promotion.position,
                    },
                ],
            },
        },
    });
};
