import { inServer } from 'core-web/constants';
import { MultiQuery } from 'core-web/libs/Algolia-v2';
import Events from 'core-web/libs/Events';
import { PageEvents, ProductEvents } from 'core-web/libs/Events/constants';
import { ResolveRedirect, ResolveSlug } from 'core-web/libs/GrebbCommerceAPI/Content';
import GetProductByPartNo from 'core-web/libs/GrebbCommerceAPI/Products/GetProductByPartNo';
import GetProductOnHand from 'core-web/libs/GrebbCommerceAPI/Products/GetProductOnHand';
import { acquireMutex } from 'core-web/state';
import { resolvePageDependencies } from 'core-web/state/models/products/actions';
import { handleOnHandData } from 'core-web/util/getProductStockStatus';
import getQueryParams from 'core-web/util/getQueryParams';
import { filterSearchParameters } from 'core-web/util/location';
import { RESOLVE_REDIRECT_PARAMETERS } from 'theme/config/constants';
import {
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_BY_ID_ERROR,
    GET_PRODUCT_BY_ID_SUCCESS,
    RESOLVE_REDIRECT,
    RESOLVE_REDIRECT_ERROR,
    RESOLVE_REDIRECT_SUCCESS,
    RESOLVE_SEARCH_SUCCESS,
    RESOLVE_SLUG,
    RESOLVE_SLUG_ERROR,
    RESOLVE_SLUG_SUCCESS,
} from './constants';

const appName = process.env.REACT_APP_NAME || '';

export const resolveSlug = (slug, search, parameters) => async (dispatch, getState) => {
    const query = getQueryParams({ search });
    const { applicationId, locale } = getState().application;
    const mutexLock = await acquireMutex('page.resolveSlug');

    if (!applicationId) {
        return null;
    }

    dispatch({ type: RESOLVE_SLUG });

    try {
        const response = await ResolveSlug(applicationId, slug, parameters);

        let { type } = response.data;

        if (type === 'imported') {
            type = 'product';
        }

        dispatch({
            type: RESOLVE_SLUG_SUCCESS,
            pageData: response.data.data,
            pageType: type,
            pageTemplate: response.data.template,
            pageRelationships: response.data.relationships,
            pageSearch: search,
        });

        if (!inServer) {
            Events.trigger(PageEvents.VIEW, { application: getState().application, page: getState().page });
        }

        // Fetch product data
        if (['product', 'spare_parts'].indexOf(type) !== -1) {
            let partNo = response.data.data.part_no || null;
            if (response.data.data.variant_part_nos.length > 0) {
                if (query.p && response.data.data.variant_part_nos.includes(query.p)) {
                    partNo = query.p;
                } else {
                    const [firstPartNo] = response.data.data.variant_part_nos;
                    partNo = firstPartNo;
                }
            }
            if (partNo) {
                dispatch({ type: GET_PRODUCT_BY_ID });
                let onHand;
                if (appName === 'duab') {
                    try {
                        const onHandResponse = await GetProductOnHand(partNo);
                        const warehouses = onHandResponse?.data?.warehouses;
                        if (warehouses?.length) {
                            const mapWarehouses = warehouses.map((warehouse) => warehouse.on_hand);
                            onHand = handleOnHandData(mapWarehouses);
                        }
                    } catch (e) {
                        console.error('Could not get all warehouses on hand, error: ', e);
                    }
                }

                const getProductPromise = GetProductByPartNo(partNo, {
                    get_historical_price: appName === 'maskinklippet',
                })
                    .then((product) => {
                        if (!inServer) {
                            Events.trigger(ProductEvents.VIEW, {
                                data: product.data,
                            });
                        }

                        const productData = { ...product.data };
                        if (onHand) {
                            productData.on_hand.leadtime_day_count = onHand.leadtime_day_count;
                            productData.on_hand.next_delivery_date = onHand.next_delivery_date;
                        }
                        const { manufacturer } = productData;
                        const indexLocale = locale.toLowerCase();

                        // We check for "-" as name because Hylte use it for unknown manufacturers
                        if (appName !== 'hylte' || (manufacturer?.unique_name && manufacturer.unique_name !== '-')) {
                            try {
                                MultiQuery([
                                    {
                                        indexName: `posts_${indexLocale}`,
                                        params: {
                                            hitsPerPage: 1,
                                            facets: ['*'],
                                            facetFilters: [
                                                [`settings.storm_code:${manufacturer.unique_name}`],
                                                ['post_type:manufacturer'],
                                            ],
                                        },
                                    },
                                    {
                                        indexName: `posts_${indexLocale}`,
                                        params: {
                                            hitsPerPage: 1,
                                            facets: ['*'],
                                            facetFilters: [
                                                [`settings.storm_unique_name:${manufacturer.unique_name}`],
                                                ['post_type:manufacturer'],
                                            ],
                                        },
                                    },
                                ]).then((res) => {
                                    const firstHit = res.results.find((result) => result.hits.length);

                                    if (firstHit) {
                                        const hit = firstHit?.hits[0];
                                        const manufacturerWithSlug = { ...manufacturer, path: hit.url };

                                        productData.manufacturer = manufacturerWithSlug;
                                    }

                                    dispatch({
                                        type: GET_PRODUCT_BY_ID_SUCCESS,
                                        product: productData,
                                        alternates: response.data.alternate || [],
                                    });
                                });
                            } catch (e) {
                                console.error(
                                    `ProductPage error: Could not fetch manufacturer from product with partNo ${partNo} from Algolia`,
                                );

                                dispatch({
                                    type: GET_PRODUCT_BY_ID_SUCCESS,
                                    product: productData,
                                    alternates: response.data.alternate || [],
                                });
                            }
                        } else {
                            dispatch({
                                type: GET_PRODUCT_BY_ID_SUCCESS,
                                product: productData,
                                alternates: response.data.alternate || [],
                            });
                        }
                    })
                    .catch(() => {
                        console.error(
                            new Error(`ProductPage error: Could not fetch product with partNo ${partNo} from the API.`),
                        );
                        dispatch({ type: GET_PRODUCT_BY_ID_ERROR });
                    });
                if (inServer) {
                    await getProductPromise;
                }
            }
        }

        mutexLock();
        return response;
    } catch (e) {
        dispatch({ type: RESOLVE_SLUG_ERROR });

        dispatch({ type: RESOLVE_REDIRECT });
        try {
            const resolveUrl =
                appName === 'duab' ? `${slug}${filterSearchParameters(search, RESOLVE_REDIRECT_PARAMETERS)}` : slug;
            const response = await ResolveRedirect(applicationId, resolveUrl, parameters);
            dispatch({
                type: RESOLVE_REDIRECT_SUCCESS,
                pageData: {
                    to: response.data.to,
                    status: response.data.status,
                },
                pageType: 'redirect',
            });
        } catch (e) {
            dispatch({ type: RESOLVE_REDIRECT_ERROR });
        }
    }

    mutexLock();
};

export const getProductByPartNo = (partNo, parameters) => async (dispatch, getState) => {
    if (partNo) {
        dispatch({ type: GET_PRODUCT_BY_ID });
        try {
            const product = await GetProductByPartNo(partNo, parameters);

            if (product) {
                dispatch({
                    type: GET_PRODUCT_BY_ID_SUCCESS,
                    product: product.data,
                });
            }
        } catch (e) {
            console.error(`ProductPage error: Could not fetch product with partNo ${partNo} from the API.`);
            dispatch({ type: GET_PRODUCT_BY_ID_ERROR });
        }
    }
};

export const resolveSearch = (search) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RESOLVE_SEARCH_SUCCESS,
            pageSearch: search,
        });
    } catch (e) {
        console.error(e);
    }
};

export const resolveSlugSSR = (location, application, url) => async (dispatch, getState) => {
    const { id: applicationId = 1, locale } = application;
    if (!applicationId || location.path.indexOf('.chunk.css.map') !== -1) {
        return null;
    }

    const query = getQueryParams(location);

    dispatch({ type: RESOLVE_SLUG });

    try {
        const response = await ResolveSlug(applicationId, location.path, {}, url);

        let { type } = response.data;

        if (type === 'imported') {
            type = 'product';
        }

        dispatch({
            type: RESOLVE_SLUG_SUCCESS,
            pageData: response.data.data,
            pageType: type,
            pageTemplate: response.data.template,
            pageRelationships: response.data.relationships,
            pageSearch: location.search,
        });

        // Fetch product data
        if (['product', 'spare_parts'].indexOf(type) !== -1) {
            let partNo = response.data.data.part_no || null;
            if (response.data.data.variant_part_nos.length > 0) {
                if (query.p && response.data.data.variant_part_nos.includes(query.p)) {
                    partNo = query.p;
                } else {
                    const [firstPartNo] = response.data.data.variant_part_nos;
                    partNo = firstPartNo;
                }
            }
            if (partNo) {
                dispatch({ type: GET_PRODUCT_BY_ID });
                let onHand;
                if (appName === 'duab') {
                    try {
                        const onHandResponse = await GetProductOnHand(partNo, { application_id: applicationId }, url);
                        const warehouses = onHandResponse?.data?.warehouses;
                        if (warehouses?.length) {
                            const mapWarehouses = warehouses.map((warehouse) => warehouse.on_hand);
                            onHand = handleOnHandData(mapWarehouses);
                        }
                    } catch (e) {
                        console.error('Could not get all warehouses on hand, error: ', e);
                    }
                }
                const getProductPromise = GetProductByPartNo(
                    partNo,
                    {
                        application_id: applicationId,
                        get_historical_price: appName === 'maskinklippet',
                    },
                    url,
                )
                    .then(async (product) => {
                        const productData = { ...product.data };
                        if (onHand) {
                            productData.on_hand.leadtime_day_count = onHand.leadtime_day_count;
                            productData.on_hand.next_delivery_date = onHand.next_delivery_date;
                        }
                        const { manufacturer } = productData;
                        const indexLocale = locale.toLowerCase();

                        // We check for "-" as name because Hylte use it for unknown manufacturers
                        if (appName !== 'hylte' || (manufacturer?.unique_name && manufacturer.unique_name !== '-')) {
                            try {
                                const algoliaResponse = await MultiQuery([
                                    {
                                        indexName: `posts_${indexLocale}`,
                                        params: {
                                            hitsPerPage: 1,
                                            facets: ['*'],
                                            facetFilters: [
                                                [`settings.storm_code:${manufacturer.unique_name}`],
                                                ['post_type:manufacturer'],
                                            ],
                                        },
                                    },
                                    {
                                        indexName: `posts_${indexLocale}`,
                                        params: {
                                            hitsPerPage: 1,
                                            facets: ['*'],
                                            facetFilters: [
                                                [`settings.storm_unique_name:${manufacturer.unique_name}`],
                                                ['post_type:manufacturer'],
                                            ],
                                        },
                                    },
                                ]);
                                const firstHit = (algoliaResponse?.results || []).find((result) => result.hits.length);

                                if (firstHit) {
                                    const hit = firstHit?.hits[0];
                                    const manufacturerWithSlug = { ...manufacturer, path: hit.url };

                                    productData.manufacturer = manufacturerWithSlug;
                                }

                                dispatch({
                                    type: GET_PRODUCT_BY_ID_SUCCESS,
                                    product: productData,
                                    alternates: response.data.alternate || [],
                                });
                            } catch (e) {
                                console.error(
                                    `ProductPage error: Could not fetch manufacturer from product with partNo ${partNo} from Algolia`,
                                );

                                dispatch({
                                    type: GET_PRODUCT_BY_ID_SUCCESS,
                                    product: productData,
                                    alternates: response.data.alternate || [],
                                });
                            }
                        } else {
                            dispatch({
                                type: GET_PRODUCT_BY_ID_SUCCESS,
                                product: productData,
                                alternates: response.data.alternate || [],
                            });
                        }
                    })
                    .catch(() => {
                        console.error(`ProductPage error: Could not fetch product with partNo ${partNo} from the API.`);
                        dispatch({ type: GET_PRODUCT_BY_ID_ERROR });
                    });
                if (inServer) {
                    await getProductPromise;
                }
            }
        }

        // Fetch and store data in redux used for site rendering
        await resolvePageDependencies(response.data, location, applicationId, url)(dispatch, getState);

        return response;
    } catch (e) {
        dispatch({ type: RESOLVE_SLUG_ERROR });

        dispatch({ type: RESOLVE_REDIRECT });
        try {
            const response = await ResolveRedirect(applicationId, location.path, {}, url);
            dispatch({
                type: RESOLVE_REDIRECT_SUCCESS,
                pageData: {
                    to: response.data.to,
                    status: response.data.status,
                },
                pageType: 'redirect',
            });
        } catch (e) {
            dispatch({ type: RESOLVE_REDIRECT_ERROR });
        }
    }
};
