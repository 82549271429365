import tracking from 'core-web/components/Tracking';
import { del } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, lineNo) => {
    const trackingData = tracking.getTrackingData();

    const data = {
        application_id: getModel('application').applicationId,
        ...trackingData,
    };

    return del(`/frontend/basket/${basketId}/item/${lineNo}`, data);
};
