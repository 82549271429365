const cloneDeep = (inObject) => {
    if (typeof inObject !== 'object' || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    const outObject = Array.isArray(inObject) ? [] : {};
    const inObjectKeys = Object.keys(inObject);

    inObjectKeys.forEach((key) => {
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = cloneDeep(inObject[key]);
    });

    return outObject;
};

export default cloneDeep;
