import React from 'react';
import loadable from '@loadable/component';
import Loading from 'theme/components/Loading';
import * as Category from './Category';
import * as Customers from './Customer';
import * as Manufacturer from './Manufacturer';
import * as Page from './Page';

const options = {
    fallback: <Loading />,
};

export default {
    not_found: loadable(() => import('./NotFound'), options),
    page: Page,
    product: loadable(() => import('./ProductPage'), options),
    spare_parts: loadable(() => import('./ProductPage'), options),
    product_category: Category,
    post: loadable(() => import('./ArticlePost'), options),
    manufacturer: Manufacturer,
    manufacturer_parts: loadable(() => import('./ManufacturerPart'), options),
    customers: Customers,
};
