// Page
export const PageEvents = {
    VIEW: 'PAGE.VIEW',
    RESOLVED: 'PAGE.RESOLVED',
    NOT_FOUND: 'NOT_FOUND',
};

// Logs including Errors
export const LogEvents = {
    LOG: 'LOG.LOG',
};

//
// Ecommerce.
//

// Basket Events.
export const BasketEvents = {
    // Basket.
    CREATED: 'BASKET.CREATED',
    SET: 'BASKET.SET',
    OPENED: 'BASKET.OPENED',
    CLOSED: 'BASKET.CLOSED',
    TOGGLED: 'BASKET.TOGGLED',
    EMPTIED: 'BASKET.EMPTIED',
    UPDATED: 'BASKET.UPDATED',

    // Products.
    PRODUCT_ADDED: 'BASKET.PRODUCT_ADDED',
    PRODUCT_REMOVED: 'BASKET.PRODUCT_REMOVED',
    PRODUCTS_ADDED: 'BASKET.PRODUCTS_ADDED',
    PRODUCTS_ONHAND_CHANGE: 'BASKET.PRODUCTS_ONHAND_CHANGE',

    // Gift Cards.
    GIFT_CARD_ADDED: 'BASKET.GIFT_CARD_ADDED',
    GIFT_CARD_ROLLBACK: 'BASKET.GIFT_CARD_ROLLBACK',

    // Vouchers.
    VOUCHER_ADDED: 'BASKET.VOUCHER_ADDED',
    VOUCHER_REMOVED: 'BASKET.VOUCHER_REMOVED',
};

export const SaleEvents = {
    UPSELL_PRODUCT_ADDED: 'SALE.UPSELL_PRODUCT_ADDED',
};

// Search Events
export const SearchEvents = {
    SUBMITTED: 'SEARCH.SUBMITTED',
    SEARCH_SUGGESTIONS: 'SEARCH.SEARCH_SUGGESTIONS',
    NO_RESULTS: 'SEARCH.NO_RESULTS',
    BRAND_SEARCH: 'SEARCH.BRAND_SEARCH',
    MOBILE_FILTER: 'SEARCH.MOBILE_FILTER',
    EXACT_CATEGORY_MATCH: 'SEARCH.EXACT_CATEGORY_MATCH',
};

// Product Events
export const ProductEvents = {
    CLICK: 'PRODUCT.CLICK',
    VIEW: 'PRODUCT.VIEW',
    IMPRESSIONS: 'PRODUCT.IMPRESSIONS',
    PRODUCTS_AMOUNT: 'PRODUCTS.AMOUNT',
    TOGGLE_VAT: 'TOGGLE_VAT',
};

export const CheckoutEvents = {
    // Checkout.
    VIEW: 'CHECKOUT.INIT',
    SHIPPING: 'CHECKOUT.SHIPPING',
    PAYMENT: 'CHECKOUT.PAYMENT',
    TRANSACTION: 'CHECKOUT.TRANSACTION',
    REFUND: 'CHECKOUT.REFUND',
    STEP: 'CHECKOUT.STEP',
};

export const PromotionEvents = {
    // Promotion
    CLICK: 'PROMOTION.CLICK',
    IMPRESSIONS: 'PROMOTION.IMPRESSIONS',
};

export const ScrollEvents = {
    TO_TOP: 'SCROLL.TO_TOP',
};

export const CustomerEvents = {
    UPDATED: 'CUSTOMER.SET',
    COMPANY_UPDATED: 'COMPANY.UPDATED',
    LOGGED_OUT: 'CUSTOMER.LOGGED_OUT',
    LOGGED_IN: 'CUSTOMER.LOGGED_IN',
};
