import {
    // Search
    SEARCH,
    SEARCH_CLEAR,
    SEARCH_CLEAR_ERROR,
    SEARCH_CLEAR_SUCCESS,
    SEARCH_DEFAULT_QUERY,
    SEARCH_DEFAULT_QUERY_ERROR,
    SEARCH_DEFAULT_QUERY_SUCCESS,
    SEARCH_ERROR,
    SEARCH_SUCCESS,
} from './constants';

// The initial state of this store.
const initialState = {
    isFetching: false,
    query: '',
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        // Search
        case SEARCH_DEFAULT_QUERY:
        case SEARCH:
        case SEARCH_CLEAR:
            return {
                ...state,
                isFetching: true,
            };
        case SEARCH_SUCCESS:
            return {
                isFetching: false,
                query: action.query,
            };
        case SEARCH_DEFAULT_QUERY_ERROR:
        case SEARCH_CLEAR_ERROR:
        case SEARCH_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        case SEARCH_CLEAR_SUCCESS:
            return {
                isFetching: false,
                query: '',
            };
        case SEARCH_DEFAULT_QUERY_SUCCESS:
            return {
                ...state,
                query: action.query,
            };
        default:
            return state;
    }
};
