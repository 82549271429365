import { API_URL, inServer } from 'core-web/constants';
import { buildQueryString, get, request } from 'core-web/libs/grebbcommerce-api/util';

export default async (applicationId, path, parameters = {}, url = undefined) => {
    // Build parameters.
    const builtParameters = buildQueryString(parameters);
    const parameterString = builtParameters ? `?${builtParameters}` : '';
    let slug = path;
    if (slug.length > 1 && slug.endsWith('/')) {
        slug = slug.slice(0, -1).toLowerCase();
    }

    const requestUrl = `/frontend/content/resolve-slug?application_id=${applicationId}&slug=${encodeURIComponent(
        slug,
    )}${parameterString}`;

    if (
        inServer &&
        process.env.REACT_APP_ALLOWED_DOMAINS &&
        process.env.REACT_APP_ALLOWED_DOMAINS.indexOf(url) === -1 &&
        API_URL
    ) {
        return request({ method: 'GET', path: requestUrl, baseUrl: API_URL });
    }

    if (!API_URL && url) {
        return request({ method: 'GET', path: requestUrl, baseUrl: url });
    }

    return get(requestUrl);
};
