import { LOGOUT, LOGOUT_SUCCESS, SET_INIT_USER_SUCCESS, SET_USER_SUCCESS, UPDATE_CUSTOMER_SUCCESS } from './constants';

const initialState = {
    code: '',
    companies: [],
    companyId: '',
    customerId: '',
    displayName: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    pricelistIds: [],
    pricelistSeed: '',
    roles: [],
    auth: false,
    isFetching: false,
    isInitialized: false,
};
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_INIT_USER_SUCCESS:
            return {
                ...state,
                isInitialized: true,
            };
        case SET_USER_SUCCESS:
            return {
                ...state,
                code: action.code,
                companies: action.companies,
                companyId: action.companyId,
                customerId: action.customerId,
                displayName: action.displayName,
                email: action.email,
                firstName: action.firstName,
                id: action.id,
                lastName: action.lastName,
                roles: action.roles,
                pricelistIds: action.pricelistIds,
                pricelistSeed: action.pricelistSeed,
                auth: action.auth,
                isFetching: action.isFetching,
                isInitialized: true,
            };

        case LOGOUT:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                pricelistSeed: action.pricelistSeed,
                companyId: action.companyId,
                customerId: action.customerId,
            };

        default:
            return state;
    }
};
