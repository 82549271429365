import fontFamilies from 'theme/config/fontFamilies';
import { css } from 'emotion';

import inter from './Inter';
import robotoCondensed from './RobotoCondensed';

export const fontString = `
    @font-face {
        font-family: ${fontFamilies.robotoCondensed};
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('${robotoCondensed.normal}') format('woff2');
    }

    @font-face {
        font-family: ${fontFamilies.inter};
        font-style: normal;
        font-weight: 100 900;
        font-display: swap;
        src: url('${inter.normal}') format('woff2');
    }
`;

export default css(fontString);
