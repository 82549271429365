import { API_URL, inServer } from 'core-web/constants';
import { get, request } from 'core-web/libs/grebbcommerce-api/util';

export default async (applicationId, url) => {
    const path = `/frontend/categories/tree?application_id=${applicationId}`;

    if (
        inServer &&
        process.env.REACT_APP_ALLOWED_DOMAINS &&
        process.env.REACT_APP_ALLOWED_DOMAINS.indexOf(url) === -1 &&
        API_URL
    ) {
        return request({ method: 'GET', path, baseUrl: API_URL });
    }

    if (!API_URL && url) {
        return request({ method: 'GET', path, baseUrl: url });
    }

    return get(path);
};
