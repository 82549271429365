import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';
import colors from 'theme/config/colors';
import RoundedButton from 'theme/components/buttons/RoundedButton';

const Form = styled('form')`
    position: relative;
`;

const Input = styled('input')`
    text-overflow: ellipsis;
    white-space: nowrap;
    background: ${colors.white};
    border: 1px solid ${colors.grey};
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 24px;
    height: 56px;
    width: 100%;
`;

const SubmitWrapper = styled('div')`
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
`;

const Label = styled('label')`
    font: 700 16px/1em 'Inter';
    margin-bottom: 16px;
    position: absolute;
    left: -5000px;
    z-index: -1;
    visibility: hidden;
`;

const NewsletterForm = ({ value, onChange, placeholder, onSubmit, buttonText, label, isFooter }) => (
    <Form onSubmit={onSubmit}>
        <Label htmlFor={`newsletter-email${isFooter ? '-footer' : ''}`}>{label}</Label>
        <Input
            required
            value={value}
            placeholder={placeholder}
            type="email"
            id={`newsletter-email${isFooter ? '-footer' : ''}`}
            name="email"
            onChange={onChange}
        />
        <SubmitWrapper>
            <RoundedButton type="submit" size="medium">
                {buttonText}
            </RoundedButton>
        </SubmitWrapper>
    </Form>
);

NewsletterForm.propTypes = {
    buttonText: PropTypes.string.isRequired,
    isFooter: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
};

NewsletterForm.defaultProps = {
    isFooter: false,
    placeholder: null,
};

export default NewsletterForm;
