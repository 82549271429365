import { type Location } from 'history';

const getQueryParams = (location: Location): Record<string, string> => {
    const params: Record<string, string> = {};

    if (location?.search) {
        const search = location.search.substr(1);

        const paramsArray = search.split('&');

        let lastAddedItemKey: string | null = null;
        paramsArray.forEach((p) => {
            const array = p.split('=');

            if (array[1]) {
                const [key, value] = array;

                lastAddedItemKey = key;
                params[array[0]] = value;
            } else if (lastAddedItemKey) {
                params[lastAddedItemKey] += `&${array[0]}`;
            }
        });
    }

    return params;
};

export default getQueryParams;
