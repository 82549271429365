import { buildQueryString, post } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, country) => {
    const data = {
        country,
    };
    const builtParameters = buildQueryString({ application_id: getModel('application').applicationId });
    const parameterString = builtParameters ? `?${builtParameters}` : '';
    return post(`/frontend/basket/${basketId}/payment-fields${parameterString}`, data);
};
