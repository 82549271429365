// import sha256 from 'sha256';
import GTM from '@grebban/gtm';
import { getModel } from 'core-web/state';
import { hashString } from 'core-web/util/hashString';
import getStormUserData from 'core-web/util/getStormUserData';
import { AFFILIATION } from 'theme/config/constants';
import { getProducts, round } from './utils/product';

export default async (orderData) => {
    const application = getModel('application');
    const {
        applied_promotions: promotions,
        format: { summary },
        id,
        info,
        items,
    } = orderData;

    const revenue = round(summary.items.amount + summary.items.vat + summary.freight.amount + summary.freight.vat);
    const revenueExVat = round(summary.items.amount + summary.freight.amount);

    const shipping = round(summary.freight.amount + summary.freight.vat);
    const tax = round(summary.items.vat + summary.freight.vat);
    const coupons = promotions.filter((p) => p.requirement_seed.split(',').includes('10'));
    const customerIsCompany = parseInt(info.find((field) => field.code === 'is_company').value, 10) === 1;

    const customerData = getStormUserData(orderData);
    const { email } = customerData;
    const lowerCaseEmail = email?.toLowerCase();
    const hashedEmailSHA1 = await hashString(email, 'SHA-1');
    const hashedEmailSHA256 = await hashString(lowerCaseEmail, 'SHA-256');

    GTM.addDataLayerEvent('transaction', {
        ecommerce: {
            purchase: {
                actionField: {
                    id,
                    affiliation: AFFILIATION,
                    revenue,
                    revenueExVat,
                    shipping,
                    tax,
                    coupon: coupons.length > 0 ? coupons.map((c) => c.discount_code).join('|') : '',
                },
                customerType: customerIsCompany ? 'B2B' : 'B2C',
                products: getProducts(items),
                customer: customerData,
            },
        },
        currency: application.currency.code,
        hashedEmailSHA1,
        hashedEmailSHA256,
    });
};
