import tracking from 'core-web/components/Tracking';
import { put } from 'core-web/libs/grebbcommerce-api/util';
import { getModel } from 'core-web/state';

export default async (basketId, lineNo, quantity) => {
    const trackingData = tracking.getTrackingData();
    const data = {
        application_id: getModel('application').applicationId,
        quantity,
        ...trackingData,
    };

    return put(`/frontend/basket/${basketId}/package/${lineNo}`, data);
};
