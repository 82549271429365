// eslint-disable-next-line eslint-comments/disable-enable-pair -- ??
/* eslint-disable sonarjs/no-duplicated-branches, sonarjs/max-switch-cases -- @TODO: combine or refactor switch cases */
import buildBasketDiscounts from 'core-web/util/buildBasketDiscounts';
import buildCartProductTree from 'core-web/util/buildCartProductTree';
import Cookies from 'js-cookie';
import {
    ADD_BASKET_ITEMS,
    ADD_BASKET_ITEMS_ERROR,
    ADD_BASKET_ITEMS_SUCCESS,
    ADD_BASKET_PAYMENT_FIELDS,
    ADD_BASKET_PAYMENT_FIELDS_ERROR,
    ADD_BASKET_PAYMENT_FIELDS_SUCCESS,
    ADD_GIFT_CARD,
    ADD_GIFT_CARD_ERROR,
    ADD_GIFT_CARD_SUCCESS,
    ADD_PACKAGE_TO_BASKET,
    ADD_PACKAGE_TO_BASKET_ERROR,
    ADD_PACKAGE_TO_BASKET_SUCCESS,
    ADD_TO_BASKET,
    ADD_TO_BASKET_ERROR,
    ADD_TO_BASKET_SUCCESS,
    ADD_VOUCHER,
    ADD_VOUCHER_ERROR,
    ADD_VOUCHER_SUCCESS,
    CLOSE_BASKET,
    CREATE_BASKET,
    CREATE_BASKET_ERROR,
    CREATE_BASKET_SUCCESS,
    EMPTY_BASKET,
    EMPTY_BASKET_ERROR,
    EMPTY_BASKET_SUCCESS,
    GET_ABANDONED_BASKET,
    GET_ABANDONED_BASKET_ERROR,
    GET_ABANDONED_BASKET_SUCCESS,
    GET_BASKET,
    GET_BASKET_ERROR,
    GET_BASKET_PAYMENT,
    GET_BASKET_PAYMENT_ERROR,
    GET_BASKET_PAYMENT_SUCCESS,
    GET_BASKET_SUCCESS,
    GET_CHECKOUT,
    GET_CHECKOUT_ERROR,
    GET_CHECKOUT_SUCCESS,
    GET_SERVICE_POINTS,
    GET_SERVICE_POINTS_ERROR,
    GET_SERVICE_POINTS_SUCCESS,
    OPEN_BASKET,
    REMOVE_BASKET,
    REMOVE_BASKET_ERROR,
    REMOVE_BASKET_SUCCESS,
    REMOVE_VOUCHER,
    REMOVE_VOUCHER_ERROR,
    REMOVE_VOUCHER_SUCCESS,
    RESET_BASKET_BUYER,
    RESET_BASKET_BUYER_ERROR,
    RESET_BASKET_BUYER_SUCCESS,
    RESET_DELIVERY_METHOD,
    RESET_DELIVERY_METHOD_ERROR,
    RESET_DELIVERY_METHOD_SUCCESS,
    ROLLBACK_GIFT_CARD,
    ROLLBACK_GIFT_CARD_ERROR,
    ROLLBACK_GIFT_CARD_SUCCESS,
    SET_BASKET,
    SET_BASKET_ERROR,
    SET_BASKET_SUCCESS,
    TOGGLE_BASKET,
    UPDATE_BASKET,
    UPDATE_BASKET_BUYER,
    UPDATE_BASKET_BUYER_ERROR,
    UPDATE_BASKET_BUYER_SUCCESS,
    UPDATE_BASKET_ERROR,
    UPDATE_BASKET_ITEM,
    UPDATE_BASKET_ITEMS,
    UPDATE_BASKET_ITEMS_ERROR,
    UPDATE_BASKET_ITEMS_SUCCESS,
    UPDATE_BASKET_ITEM_ERROR,
    UPDATE_BASKET_ITEM_SUCCESS,
    UPDATE_BASKET_PACKAGE_ITEM,
    UPDATE_BASKET_PACKAGE_ITEM_ERROR,
    UPDATE_BASKET_PACKAGE_ITEM_SUCCESS,
    UPDATE_BASKET_SECRET_ERROR,
    UPDATE_BASKET_SECRET_SUCCESS,
    UPDATE_BASKET_SUCCESS,
    UPDATE_DELIVERY_METHOD,
    UPDATE_DELIVERY_METHOD_ERROR,
    UPDATE_DELIVERY_METHOD_SUCCESS,
    UPDATE_ITEM_DISCOUNTS,
    UPDATE_ITEM_DISCOUNTS_ERROR,
    UPDATE_ITEM_DISCOUNTS_SUCCESS,
    UPDATE_ITEM_PRICE,
    UPDATE_ITEM_PRICE_ERROR,
    UPDATE_ITEM_PRICE_SUCCESS,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_ERROR,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_SECRET_BASKET,
    UPDATE_SECRET_BASKET_BUYER,
    UPDATE_SECRET_BASKET_BUYER_ERROR,
    UPDATE_SECRET_BASKET_BUYER_SUCCESS,
    UPDATE_SERVICE_POINT,
    UPDATE_SERVICE_POINT_ERROR,
    UPDATE_SERVICE_POINT_SUCCESS,
} from './constants';

const initialState = {
    basketId: Cookies.get('basket_id') || null,
    comment: '',
    companyId: '',
    currency: null,
    customerId: '',
    deliveryMethods: [],
    discounts: {
        basket: 0,
        totalDiscount: 0,
        items: {},
    },
    info: [],
    isFetching: false,
    isLoading: false,
    isModifyingItems: false,
    isOpen: false,
    items: null, // []
    payment: null,
    paymentMethods: [],
    payments: [],
    promotions: [],
    summary: [],
    textComments: [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        // Get Basket
        case GET_BASKET:
        case GET_ABANDONED_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case GET_BASKET_SUCCESS:
        case GET_ABANDONED_BASKET_SUCCESS: {
            const GET_ABANDONED_BASKET_SUCCESS_DATA = {
                ...state,
                basketId: action.basketId,
                comment: action.comment,
                companyId: action.companyId,
                currency: action.currency,
                customerId: action.customerId,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                info: action.info,
                isFetching: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                payments: action.payments,
                promotions: action.promotions,
                summary: action.summary,
                textComments: action.textComments,
            };
            if (action.shouldOverwriteTextComments) {
                GET_ABANDONED_BASKET_SUCCESS_DATA.textComments = action.textComments;
            }

            return GET_ABANDONED_BASKET_SUCCESS_DATA;
        }
        case GET_BASKET_ERROR:
        case GET_ABANDONED_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Set Basket
        case SET_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case SET_BASKET_SUCCESS:
            return {
                ...state,
                basketId: action.basketId,
                isFetching: false,
            };
        case SET_BASKET_ERROR:
            return {
                ...state,
                isFetching: false,
            };

        // Update Basket
        case UPDATE_BASKET:
            return {
                ...state,
                isModifyingBasket: true,
            };
        case UPDATE_BASKET_SUCCESS: {
            const UPDATE_BASKET_SUCCESS_DATA = {
                ...state,
                comment: action.comment,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                info: action.info,
                isModifyingBasket: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                summary: action.summary,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_BASKET_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_BASKET_SUCCESS_DATA;
        }
        case UPDATE_BASKET_ERROR:
            return {
                ...state,
                isModifyingBasket: false,
            };

        // Update secret basket
        case UPDATE_SECRET_BASKET:
            return {
                ...state,
                isModifyingBasket: true,
            };
        case UPDATE_BASKET_SECRET_SUCCESS: {
            const UPDATE_BASKET_SECRET_SUCCESS_DATA = {
                ...state,
                comment: action.comment,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                info: action.info,
                isModifyingBasket: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                percantage: action.percantage,
                price: action.price,
                summary: action.summary,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_BASKET_SECRET_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_BASKET_SECRET_SUCCESS_DATA;
        }
        case UPDATE_BASKET_SECRET_ERROR:
            return {
                ...state,
                isModifyingBasket: false,
            };

        // Create Basket
        case CREATE_BASKET:
            return {
                ...state,
                isFetching: true,
            };
        case CREATE_BASKET_SUCCESS:
            return {
                ...state,
                basketId: action.basketId,
                currency: action.currency,
                info: action.info,
                isFetching: false,
                customerId: action.customerId,
                companyId: action.companyId,
            };
        case CREATE_BASKET_ERROR:
            return {
                ...state,
            };

        // Add to Basket
        case ADD_TO_BASKET:
        case ADD_BASKET_ITEMS:
            return {
                ...state,
                isModifyingItems: true,
            };
        case ADD_TO_BASKET_SUCCESS:
        case ADD_BASKET_ITEMS_SUCCESS: {
            const ADD_BASKET_ITEMS_SUCCESS_DATA = {
                ...state,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                info: action.info,
                isModifyingItems: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                promotions: action.promotions,
                summary: action.summary,
            };
            if (action.shouldOverwriteTextComments) {
                ADD_BASKET_ITEMS_SUCCESS_DATA.textComments = action.textComments;
            }

            return ADD_BASKET_ITEMS_SUCCESS_DATA;
        }
        case ADD_TO_BASKET_ERROR:
        case ADD_BASKET_ITEMS_ERROR:
            return {
                ...state,
                isModifyingItems: false,
            };

        // Add package to Basket
        case ADD_PACKAGE_TO_BASKET:
            return {
                ...state,
                isModifyingItems: true,
            };
        case ADD_PACKAGE_TO_BASKET_SUCCESS: {
            const ADD_PACKAGE_TO_BASKET_SUCCESS_DATA = {
                ...state,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                isModifyingItems: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                summary: action.summary,
            };
            if (action.shouldOverwriteTextComments) {
                ADD_PACKAGE_TO_BASKET_SUCCESS_DATA.textComments = action.textComments;
            }

            return ADD_PACKAGE_TO_BASKET_SUCCESS_DATA;
        }
        case ADD_PACKAGE_TO_BASKET_ERROR:
            return {
                ...state,
                isModifyingItems: false,
            };

        // Remove Basket
        case REMOVE_BASKET:
            return {
                ...state,
                isModifyingItems: true,
            };
        case REMOVE_BASKET_SUCCESS:
            return {
                ...initialState,
                basketId: null, // needed for ssr
            };
        case REMOVE_BASKET_ERROR:
            return {
                ...state,
                isModifyingItems: false,
            };

        // Update Basket Item
        case UPDATE_BASKET_PACKAGE_ITEM:
        case UPDATE_BASKET_ITEM:
        case UPDATE_BASKET_ITEMS:
            return {
                ...state,
                isModifyingItems: true,
            };
        case UPDATE_BASKET_PACKAGE_ITEM_SUCCESS:
        case UPDATE_BASKET_ITEM_SUCCESS:
        case UPDATE_BASKET_ITEMS_SUCCESS: {
            const UPDATE_BASKET_ITEMS_SUCCESS_DATA = {
                ...state,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                info: action.info,
                isModifyingItems: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                promotions: action.promotions,
                summary: action.summary,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_BASKET_ITEMS_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_BASKET_ITEMS_SUCCESS_DATA;
        }
        case UPDATE_BASKET_PACKAGE_ITEM_ERROR:
        case UPDATE_BASKET_ITEM_ERROR:
        case UPDATE_BASKET_ITEMS_ERROR:
            return {
                ...state,
                isModifyingItems: false,
            };

        // Update Item Price
        case UPDATE_ITEM_PRICE:
            return {
                ...state,
                isModifyingItems: true,
            };
        case UPDATE_ITEM_PRICE_SUCCESS: {
            const UPDATE_ITEM_PRICE_SUCCESS_DATA = {
                ...state,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                isModifyingItems: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComment, state),
                summary: action.summary,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_ITEM_PRICE_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_ITEM_PRICE_SUCCESS_DATA;
        }
        case UPDATE_ITEM_PRICE_ERROR:
            return {
                ...state,
                isModifyingItems: false,
            };

        // Empty Basket
        case EMPTY_BASKET:
            return {
                ...state,
                isModifyingItems: true,
            };
        case EMPTY_BASKET_SUCCESS:
            return {
                ...state,
            };
        case EMPTY_BASKET_ERROR:
            return {
                ...state,
                isModifyingItems: false,
            };

        // Show/Hide the Basket
        case OPEN_BASKET:
            return {
                ...state,
                isOpen: true,
            };
        case CLOSE_BASKET:
            return {
                ...state,
                isOpen: false,
            };
        case TOGGLE_BASKET:
            return {
                ...state,
                isOpen: !state.isOpen,
            };

        // Add Gift Card
        case ADD_GIFT_CARD:
            return {
                ...state,
                isLoading: true,
            };
        case ADD_GIFT_CARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ADD_GIFT_CARD_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        // Remove Gift Card
        case ROLLBACK_GIFT_CARD:
            return {
                ...state,
                isLoading: true,
            };
        case ROLLBACK_GIFT_CARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ROLLBACK_GIFT_CARD_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        // Remove from Basket
        case ADD_VOUCHER:
            return {
                ...state,
                isLoading: true,
            };

        case ADD_VOUCHER_SUCCESS: {
            const ADD_VOUCHER_SUCCESS_DATA = {
                ...state,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                isLoading: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                promotions: action.promotions,
                summary: action.summary,
            };

            if (action.shouldOverwriteTextComments) {
                ADD_VOUCHER_SUCCESS_DATA.textComments = action.textComments;
            }

            return ADD_VOUCHER_SUCCESS_DATA;
        }
        case ADD_VOUCHER_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        // Remove from Basket
        case REMOVE_VOUCHER:
            return {
                ...state,
            };

        case REMOVE_VOUCHER_SUCCESS: {
            const REMOVE_VOUCHER_SUCCESS_DATA = {
                ...state,
                discounts: buildBasketDiscounts(action.items) || state.discounts,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                promotions: action.promotions,
                summary: action.summary,
                textComments: action.textComments,
            };
            if (action.shouldOverwriteTextComments) {
                REMOVE_VOUCHER_SUCCESS_DATA.textComments = action.textComments;
            }

            return REMOVE_VOUCHER_SUCCESS_DATA;
        }
        case REMOVE_VOUCHER_ERROR:
            return {
                ...state,
            };

        // Update payment method
        case UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: [],
                isLoading: true,
            };

        case UPDATE_PAYMENT_METHOD_SUCCESS: {
            const UPDATE_PAYMENT_METHOD_SUCCESS_DATA = {
                ...state,
                isLoading: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                deliveryMethods: action.isAuth ? action.deliveryMethods : state.deliveryMethods,
                paymentMethods: action.paymentMethods,
                payments: action.payments,
                summary: action.summary,
                textComments: action.textComments,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_PAYMENT_METHOD_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_PAYMENT_METHOD_SUCCESS_DATA;
        }
        case UPDATE_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                deliveryMethods: [],
                paymentMethods: [],
                isLoading: false,
            };

        // Update delivery method
        case UPDATE_DELIVERY_METHOD:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_DELIVERY_METHOD_SUCCESS: {
            const UPDATE_DELIVERY_METHOD_SUCCESS_DATA = {
                ...state,
                deliveryMethods: action.deliveryMethods || state.deliveryMethods,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                paymentMethods: action.paymentMethods,
                summary: action.summary,
                deliveryPostCode: action.postcode,
                shippingProviderCode: action.methodCode,
                isLoading: false,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_DELIVERY_METHOD_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_DELIVERY_METHOD_SUCCESS_DATA;
        }
        case UPDATE_DELIVERY_METHOD_ERROR:
            return {
                ...state,
                deliveryMethods: false,
                isLoading: false,
            };

        // Reset delivery method
        case RESET_DELIVERY_METHOD:
            return {
                ...state,
                deliveryMethods: null,
                paymentMethods: null,
                isLoading: true,
            };

        case RESET_DELIVERY_METHOD_SUCCESS:
            return {
                ...state,
                deliveryMethods: action.deliveryMethods,
                paymentMethods: action.paymentMethods,
                isLoading: false,
            };
        case RESET_DELIVERY_METHOD_ERROR:
            return {
                ...state,
                deliveryMethods: false,
                paymentMethods: false,
                isLoading: false,
            };

        // Update delivery points
        case GET_SERVICE_POINTS:
            return {
                ...state,
                isLoading: true,
            };

        case GET_SERVICE_POINTS_SUCCESS:
            return {
                ...state,
                customerSupportPhone: action.customerSupportPhone,
                servicePoints: action.servicePoints,
                deliveryPostCode: action.deliveryPostCode,
                isLoading: false,
                shippingProviderCode: action.methodCode,
            };

        case GET_SERVICE_POINTS_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        case UPDATE_SERVICE_POINT:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_SERVICE_POINT_SUCCESS:
            return {
                ...state,
                servicePoint: action.servicePoint,
                info: action.info,
                isLoading: false,
            };

        case UPDATE_SERVICE_POINT_ERROR:
            return {
                ...state,
                isLoading: false,
            };

        // Get basket payment
        case GET_BASKET_PAYMENT:
            return {
                ...state,
                payment: null,
                isLoading: true,
            };

        case GET_BASKET_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: action.payment,
                isLoading: false,
            };
        case GET_BASKET_PAYMENT_ERROR:
            return {
                ...state,
                payment: false,
                isLoading: false,
            };

        // Get checkout
        case GET_CHECKOUT:
            return {
                ...state,
                isLoading: true,
            };

        case GET_CHECKOUT_SUCCESS: {
            const data = {
                ...state,
                isLoading: false,
                paymentMethods: action.paymentMethods,
                payments: action.payments,
                summary: action.summary,
            };

            if (action.returnDeliveryMethods) {
                data.deliveryMethods = action.deliveryMethods || [];
            }

            return data;
        }

        case GET_CHECKOUT_ERROR:
            return {
                ...state,
                isLoading: false,
            };
        // Add basket payment fields
        case ADD_BASKET_PAYMENT_FIELDS:
            return {
                ...state,
            };
        case ADD_BASKET_PAYMENT_FIELDS_SUCCESS:
            return {
                ...state,
            };
        case ADD_BASKET_PAYMENT_FIELDS_ERROR:
            return {
                ...state,
            };
        case UPDATE_ITEM_DISCOUNTS:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_ITEM_DISCOUNTS_ERROR:
            return {
                ...state,
                isLoading: false,
            };
        case UPDATE_ITEM_DISCOUNTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                discounts: buildBasketDiscounts(action.items) || action.discounts || state.discounts,
                summary: action.summary,
            };
        }
        case UPDATE_BASKET_BUYER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case UPDATE_BASKET_BUYER_ERROR: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case UPDATE_BASKET_BUYER_SUCCESS: {
            const UPDATE_BASKET_BUYER_SUCCESS_DATA = {
                ...state,
                isLoading: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                summary: action.summary,
                customerId: action.customerId,
                companyId: action.companyId,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_BASKET_BUYER_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_BASKET_BUYER_SUCCESS_DATA;
        }
        case RESET_BASKET_BUYER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case RESET_BASKET_BUYER_ERROR: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case RESET_BASKET_BUYER_SUCCESS: {
            const RESET_BASKET_BUYER_SUCCESS_DATA = {
                ...state,
                isLoading: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                summary: action.summary,
                customerId: '',
                companyId: '',
            };
            if (action.shouldOverwriteTextComments) {
                RESET_BASKET_BUYER_SUCCESS_DATA.textComments = action.textComments;
            }

            return RESET_BASKET_BUYER_SUCCESS_DATA;
        }
        case UPDATE_SECRET_BASKET_BUYER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case UPDATE_SECRET_BASKET_BUYER_ERROR: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case UPDATE_SECRET_BASKET_BUYER_SUCCESS: {
            const UPDATE_SECRET_BASKET_BUYER_SUCCESS_DATA = {
                ...state,
                isLoading: false,
                items: buildCartProductTree(action.items, true, action.shouldOverwriteTextComments, state),
                summary: action.summary,
                customerId: action.customerId,
                companyId: action.companyId,
            };
            if (action.shouldOverwriteTextComments) {
                UPDATE_SECRET_BASKET_BUYER_SUCCESS_DATA.textComments = action.textComments;
            }

            return UPDATE_SECRET_BASKET_BUYER_SUCCESS_DATA;
        }
        default:
            return state;
    }
};
