import LanguageLinks from 'core-web/components/Meta/LanguageLinks';
import ProductStructuredData from 'core-web/components/Meta/StructuredData/ProductStructuredData';
import { getParametric } from 'core-web/containers/ProductPageContainer';
import { stormSrc } from 'core-web/libs/image';
import { injectModels } from 'core-web/state';
import { checkStormMediaUrl } from 'core-web/util/checkStormMediaUrl';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import { getProductAvailability } from 'core-web/util/getProductAvailability';
import { getHreflangs, getLanguages } from 'core-web/util/multimarket';
import { formattedUrl } from 'core-web/util/storm';
import toFixedCurrency from 'core-web/util/toFixedCurrency';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { GLOBAL_PRODUCT_DESC, STORM_FILE_IMAGE_TYPES } from 'theme/config/constants';

class ProductMeta extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired,
        page: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        selectedVariant: PropTypes.object,
        type: PropTypes.string,
    };

    static defaultProps = {
        selectedVariant: null,
        type: '',
    };

    static subDesc = (string) =>
        string.length >= 160
            ? string
                  .replace(/<\/{0,1}[^>]+>/g, '')
                  .slice(0, 157)
                  .concat('...')
            : string.replace(/<\/{0,1}[^>]+>/g, '');

    static getImagesFromFiles = (files) =>
        files.reduce((acc, cur) => {
            const { type, format } = cur;

            if (format.image && STORM_FILE_IMAGE_TYPES.includes(type)) {
                return [...acc, checkStormMediaUrl(format.image)];
            }

            return acc;
        }, []);

    shouldComponentUpdate(nextProps) {
        const { product } = this.props;
        return JSON.stringify(nextProps.product) !== JSON.stringify(product);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity -- temporary
    render() {
        const appName = process.env.REACT_APP_NAME || '';
        const { application, product, type, page, selectedVariant } = this.props;
        const globalProductDesc = getParametric(GLOBAL_PRODUCT_DESC, product)?.value;
        const locale = application?.locale;

        if (!product) {
            return null;
        }

        const protocol = application?.protocol || '';
        const origin = application?.origin || '';
        const baseUrl = protocol + origin;

        const currency = application?.currency?.code || null;
        const imageSrc = (product.format || {}).image || (product.files[0] && product.files[0].format.image);
        const mpn = product.manufacturer?.part_no || null;
        const sku = mpn;
        const ean = product.ean_code;
        const brand = product.manufacturer?.name || product.manufacturer?.unique_name;
        const uniqueProductName = page.data.default_unique_url_name;

        const priceIncVat = product.price_inc_vat;
        const hasDecimalPrice = locale === 'fi_FI' || appName === 'duab';
        const price = hasDecimalPrice
            ? toFixedCurrency(Math.round(priceIncVat * 100) / 100, 2, false)
            : Math.round(priceIncVat).toFixed(2);

        const includedProducts = product.included_products;
        const images = ProductMeta.getImagesFromFiles(product.files);

        if (imageSrc) {
            images.unshift(stormSrc(imageSrc, { m: 'pad', f: 'jpg' }));
        }

        let title = product.name || '';
        if (appName === 'hylte') {
            title = product.description_header || title;
        } else if (appName === 'duab') {
            title = product.meta_tags || title || product.title || selectedVariant?.variant_name;
        }

        if (type === 'spare_parts') {
            title = `${brand} ${title} ${mpn}`;
        }

        // @todo: get the HTMLparser lib to work properly instead of this.
        let metaDescription = product.description || product.sub_description || globalProductDesc || null;

        if (appName === 'duab') {
            const shortDescription = product.variant_parametrics?.find(
                (param) => param.code === 'du_product_short_desc' || param.code === 'du_product_long_desc',
            )?.value;
            metaDescription = shortDescription || metaDescription;
        } else if (appName === 'hylte') {
            metaDescription = product.meta_description || metaDescription;
        }

        let productUrl = baseUrl;
        if (appName === 'hylte') {
            productUrl += `/${uniqueProductName}`;
        } else if (type === 'spare_parts') {
            productUrl += `/reservdelar/${product.manufacturer.format.slug}/${uniqueProductName}`;
        } else {
            productUrl += formattedUrl(product.categories, uniqueProductName);
        }

        const keywords = product.keywords || null;

        const productAvailability = getProductAvailability(product, locale);

        const ogType = 'product';
        const ogTitle = title;
        const ogDescription = metaDescription || null;
        const ogImage = imageSrc;
        const ogUrl = productUrl || null;
        const ogLocale = locale || null;
        const ogProductPrice = price;
        const ogProductCurrency = currency;

        let ogProductAvailability = 'out_of_stock';

        if (productAvailability.inStock) {
            ogProductAvailability = 'in_stock';
        } else if (productAvailability.preOrder || productAvailability.backOrder) {
            ogProductAvailability = 'available for order';
        }

        const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

        const alternateHrefs = page?.data?.alternates || null;
        let hreflangs;

        if (alternateHrefs) {
            const languages = getLanguages(application.languages, ogLocale);
            hreflangs = getHreflangs(alternateHrefs, languages, page);
        }

        const isInternationalMarket = locale && locale !== 'sv_SE';
        const breadcrumbs = page.data.bread_crumbs;
        const deepestCategory = breadcrumbs?.map((breadcrumb) => breadcrumb.title).pop();
        const deepestCategoryBackup = JSON.parse(JSON.stringify(product.categories))
            ?.reverse()
            .find((category) => category.value !== null)?.value;
        const applicationName = application.config.options.organization.name;
        const applicationNameString = applicationName ? ` - ${decodeHTMLEntities(applicationName)}` : '';
        const productTitle = `${decodeHTMLEntities(title)} - ${decodeHTMLEntities(
            deepestCategory || deepestCategoryBackup,
        )}${applicationNameString}`;

        return (
            <>
                <Helmet>
                    {title && <title>{productTitle}</title>}
                    {productUrl && <link rel="canonical" href={productUrl} />}
                    {metaDescription && (
                        <meta name="description" content={ProductMeta.subDesc(decodeHTMLEntities(metaDescription))} />
                    )}
                    {keywords && <meta name="keywords" content={keywords} />}

                    {ogType && <meta property="og:type" content={ogType} />}
                    {ogTitle && <meta property="og:title" content={ogTitle} />}
                    {ogDescription && <meta property="og:description" content={ProductMeta.subDesc(ogDescription)} />}
                    {ogImage && <meta property="og:image" content={ogImage} />}
                    {ogUrl && <meta property="og:url" content={ogUrl} />}
                    {ogLocale && <meta property="og:locale" content={ogLocale} />}
                    {ogProductPrice && <meta property="og:price:amount" content={ogProductPrice} />}
                    {ogProductCurrency && <meta property="og:price:currency" content={ogProductCurrency} />}
                    {brand && <meta property="product:brand" content={brand} />}
                    {ogProductAvailability && <meta property="product:availability" content={ogProductAvailability} />}
                    <meta property="product:condition" content="new" />
                    {ogLocale && <meta property="product:locale" content={ogLocale} />}
                    {ogProductPrice && <meta property="product:price:amount" content={ogProductPrice} />}
                    {ogProductCurrency && <meta property="product:price:currency" content={ogProductCurrency} />}
                    {facebookAppId && <meta property="fb:app_id" content={facebookAppId} />}
                </Helmet>
                <ProductStructuredData
                    brand={brand}
                    breadcrumbsData={breadcrumbs}
                    categories={product.categories}
                    currency={currency}
                    description={metaDescription}
                    ean={ean}
                    images={images}
                    includedProducts={includedProducts}
                    hasDecimalPrice={hasDecimalPrice}
                    toFixedCurrency={toFixedCurrency}
                    productAvailability={productAvailability}
                    mpn={mpn}
                    name={title}
                    price={price}
                    product={product}
                    sku={sku}
                    url={productUrl}
                    variants={product.variants || []}
                    isInternationalMarket={isInternationalMarket}
                />
                {hreflangs && <LanguageLinks links={hreflangs} />}
            </>
        );
    }
}

export default injectModels(['application', 'page'])(ProductMeta);
export { ProductMeta as DefaultProductMeta };
